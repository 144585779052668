import React from "react";

// dialog resources
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const IotaDialog = ({ dialog, isOpen, onClose, save, children }) => (
  <Dialog
    fullWidth
    maxWidth={dialog.maxWidth || "md"}
    open={isOpen}
    onClose={onClose}
    aria-label={dialog.title}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
        color="primary"
        disabled={dialog.cancelDisabled || false}
      >
        {dialog.cancel}
      </Button>
      {save}
    </DialogActions>
  </Dialog>
);

export default IotaDialog;
