import React from "react";

class ChartZoomController extends React.Component {
  state = {
    zoomArea: [],
    zoomAxis: []
  };

  setZoomArea = (key, event) => {
    if (event) {
      const newZoomArea = this.state.zoomArea.slice(0);
      newZoomArea[key] = event.activeTooltipIndex;
      this.setState({
        zoomArea: newZoomArea,
        zoomAxis: this.setZoomAxis(newZoomArea)
      });
    }
  };

  setZoomAxis = zoomArea => {
    const [zoomAxisStart, zoomAxisEnd] = zoomArea;
    const { data } = this.props;
    let zoomAxis = [];
    if (zoomAxisStart !== undefined) {
      zoomAxis[0] = data[zoomAxisStart].time;
    }
    if (zoomAxisEnd !== undefined) {
      zoomAxis[1] = data[Math.min(zoomAxisEnd + 1, data.length - 1)].time;
    }
    return zoomAxis;
  };

  zoom = () => {
    const { zoomArea } = this.state;
    const { canZoomIn, loadData } = this.props;
    if (canZoomIn && zoomArea[0] !== zoomArea[1]) {
      loadData(zoomArea);
      this.setState({ zoomArea: [], zoomAxis: [] });
    }
  };

  onMouseDown = e => this.props.canZoomIn && this.setZoomArea(0, e);
  onMouseMove = e =>
    this.props.canZoomIn && this.state.zoomAxis[0] && this.setZoomArea(1, e);

  render() {
    const { onMouseDown, onMouseMove, zoom: onMouseUp, state, props } = this;
    const { zoomAxis } = state;

    return props.children({
      zoomAxis,
      onMouseDown,
      onMouseMove,
      onMouseUp
    });
  }
}

export default ChartZoomController;
