import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

class Details extends React.Component {
  static propTypes = {
    results: PropTypes.array,
    status: PropTypes.string,
    current: PropTypes.object,
    pagesCount: PropTypes.number
  };

  state = {
    showResults: false
  };

  toggleShow = () => {
    const showResults = !this.state.showResults;
    this.setState({ showResults });
  };

  logger = item => `Device ${item.id}`;

  errorMsg = msg => <span style={{ color: "red" }}>{msg}</span>;

  result = ({ code = "", msg = "", type }) => {
    if (type && type !== "created") {
      return this.errorMsg(`Invalid data: ${code} ${msg}`);
    } else {
      return "OK";
    }
  };

  countPages = (results, predicate) => results.filter(predicate).length;

  countFailedPages = results =>
    this.countPages(results, ({ result }) => result.type === "server_error");

  countErrorPages = results =>
    this.countPages(results, ({ result }) => result.type === "error");

  render() {
    const { status, results, current } = this.props;
    const { showResults } = this.state;
    const failedCount = this.countFailedPages(results);
    const errorCount = this.countErrorPages(results);
    const failed = failedCount > 0;
    const serverError = errorCount > 0;
    const isRunning = status === "started";
    return (
      <div>
        <div>
          {failed && (
            <h4 style={{ color: "red" }}>
              <span>Devices failed to create:</span>
              {failedCount}
            </h4>
          )}
          {!failed &&
            !serverError && <h4 style={{ color: "green" }}>No errors</h4>}
          {(isRunning || failed || serverError) && (
            <>
              {serverError && (
                <p style={{ color: "red" }}>
                  <span>Devices failed to create:</span>
                  {errorCount}
                </p>
              )}
            </>
          )}
        </div>
        <div>
          <Button color="primary" onClick={this.toggleShow}>
            {showResults ? "Hide" : "Show"} progress result
          </Button>
        </div>
        <div style={{ display: showResults ? "block" : "none" }}>
          <div>
            {status === "checking" && <p>{this.logger(current)} Checking...</p>}
            {results.map((item, i) => (
              <p key={`device-${results.length - i}`}>
                {this.logger(item)} {this.result(item.result)}
              </p>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Details;
