import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Paper,
  Typography,
} from "@material-ui/core";

import HttpClient from "../HttpClient";
import Config from "../Config";
import AuthLayout from "./AuthLayout";


const FAILURE_MESSAGE =
  "Activation token was invalid, please use link from e-mail message";
const SUCCESS_MESSAGE = "Account successfully activated, please sign in";

class ActivationPage extends Component {
  state = {
    requestFinished: false,
    type: "error",
    message: null,
  };

  componentDidMount() {
    const token = this.props.match.params.token;
    HttpClient(Config.api.HOST + "/activate_account", {
      method: "POST",
      body: JSON.stringify({ token })
    })
      .then(result => {
        this.setState({
          requestFinished: true,
          message: SUCCESS_MESSAGE,
          type: "success"
        });
      })
      .catch(error => {
        this.setState({
          requestFinished: true,
          message: FAILURE_MESSAGE,
          type: "error"
        });
      });
  }

  render() {
    const {
      requestFinished,
      type,
      message
    } = this.state;

    return (
      <>
        {requestFinished && (
          <Redirect
            to={{
              pathname: "/login",
              state: { notification: { type, message } }
            }}
          />
        )}

        <AuthLayout>
          <Paper>
            <Typography component="h1" variant="display1">
              Activation page
            </Typography>
            <div>Please wait for account confirmation</div>
          </Paper>
        </AuthLayout>
      </>
    );
  }
}

export default ActivationPage;
