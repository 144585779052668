import React from "react";
import Button from "@material-ui/core/Button";
import { translate } from "react-admin";
import { withStyles } from "@material-ui/core";


const styles = theme => ({
  rangeButton: {
    margin: "0 5px", 
    [theme.breakpoints.down("xs")]: {
      margin: "0",
      paddingLeft: "2px",
      paddingRight: "2px",
      fontSize: "0.675rem"
    }
  }
});


const ChartNavButton = props => {
  const { text, active, onClick, translate } = props;
  return (
    <Button
      variant={active ? "contained" : "flat"}
      color="primary"
      fullWidth={true}
      className={props.classes.rangeButton}
      onClick={onClick}
    >
      {translate(`monitors.range_button.${text}`)}
    </Button>
  );
};

export default translate( withStyles(styles)(ChartNavButton));
