import React from "react";
import DeviceAndTime from "./DeviceAndTime";
import ValueWithLabel, { labelWithSubscript, formattedValue } from "./ValueWithLabel";

export default function Temphumidco2Meter(props) {
    const { aggregate: { values = [] } } = props;
    const [temperature, temperaturemom, humidity, humiditymom, co2, co2mom] = values;
    return (
        <div className="absolute-container">
            <DeviceAndTime {...props} />
            <div className="temphumid-data">
                <div className="values-column">
                    <ValueWithLabel label={labelWithSubscript("T", "ave")} className="temphumid-t">
                        <div>{formattedValue(temperature, 1, "°")}</div>
                    </ValueWithLabel>
                    <ValueWithLabel label={labelWithSubscript("T", "end")} className="temphumid-t">
                        <div>{formattedValue(temperaturemom, 1, "°")}</div>
                    </ValueWithLabel>
                </div>
                <div className="values-column">
                    <ValueWithLabel label={labelWithSubscript("H", "ave")} className="temphumid-h">
                        <div>{formattedValue(humidity, 0, "%")}</div>
                    </ValueWithLabel>
                    <ValueWithLabel label={labelWithSubscript("H", "end")} className="temphumid-h">
                        <div>{formattedValue(humiditymom, 0, "%")}</div>
                    </ValueWithLabel>
                </div>
                <div className="values-column">
                    <ValueWithLabel label={labelWithSubscript("C", "ave")} className="temphumid-co2">
                        <div>{formattedValue(co2, 0, "ppm", true)}</div>
                    </ValueWithLabel>
                    <ValueWithLabel label={labelWithSubscript("C", "end")} className="temphumid-co2">
                        <div>{formattedValue(co2mom, 0, "ppm", true)}</div>
                    </ValueWithLabel>
                </div>
            </div>
        </div>
    );
}
