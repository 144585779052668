const messages = {
  en: {
    'duplicate key value violates unique constraint "device_pkey"':
      "This device is already registered",
    invalid_device: "Device doesn't exist, please contact the device owner.",
    messages: {
      validator: {
        invalid_credentials: "Invalid validator credentials",
        device_not_registered:
          "These ID and PSC doesn't match any registered device. Please contact IOTA Admin.",
        not_registered_error: "Please contact IOTA Admin."
      },
      device_not_found: "Device not exists",
      device_already_added: "Device already added",
      device_added: "New device has been added to your list of charts.",
      monitor_device_added: "Monitor device added",
      monitor_device_removed: "Monitor device removed",
      device_deleted: "Device deleted",
      data_alerts_deleted: "Notifications deleted",
      data_alerts_not_deleted: "Error: Notifications not deleted"
    },
    xls: {
      range_button: {
        all: "All",
        "30_days": "30 days",
        "7_days": "7 days",
        "24_hours": "24 hours",
        "4_hours": "4 hours"
      }
    },
    monitors: {
      add: "Add",
      range_button: {
        "30_days": "30 days",
        "7_days": "7 days",
        "24_hours": "24 hours",
        "4_hours": "4 hours"
      }
    }
  }
};

export default messages;
