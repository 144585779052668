import React from "react";
import { Filter, ReferenceInput, SelectInput, TextInput } from "react-admin";
import { withStyles } from "@material-ui/core";

export const StyledSelectInput = withStyles ({
  input: { minWidth: "140px"}
})(SelectInput)

const MonitorFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="fts.search_query" alwaysOn />
      <ReferenceInput
        label="Meter type"
        source="meter_type"
        reference="meter_types"
        allowEmpty
        alwaysOn
      >
        <StyledSelectInput optionText="name" />
      </ReferenceInput>
    </Filter>
  );
};

export default MonitorFilter;
