import React from "react";
import Button from "@material-ui/core/Button";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

const MonitorSortButton = ({ label, field, setSort, currentSort }) => {
  const icon = field === currentSort.field
    ? currentSort.order === 'DESC' ? <ArrowDownward fontSize="inherit"/> : <ArrowUpward fontSize="inherit"/>
    : null;

  return <Button
    fullWidth={false}
    onClick={() => setSort(field)}
    size="small"
    variant="text"
    color="primary"
  >
    {label}
    {icon}
  </Button>
};

export default MonitorSortButton;
