import React from "react";

import { connect } from "react-redux";
import { change, submit, isValid, reset } from "redux-form";

import Button from "@material-ui/core/Button";
import ExportIcon from "@material-ui/icons/Description";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { fetchEnd, fetchStart, showNotification } from "react-admin";

import HttpClient from "../../../../HttpClient";
import Config from "../../../../Config";

import XlsFormDialog from "./XlsFormDialog";
import { bulkIconStyles } from "../MonitorBulkEdit";



const FORM_NAME = `monitor_xls_export`;

class MonitorXlsExport extends React.Component {
  state = {
    showFormDialog: false,
    isSubmitting: false
  };

  handleShowForm = () => {
    this.setState({ showFormDialog: true });
  };

  handleCloseForm = () => {
    this.props.reset(FORM_NAME);
    this.setState({ isSubmitting: false, showFormDialog: false });
  };

  handleSubmit = () => {
    const { submit } = this.props;
    this.setState({ isSubmitting: true });
    submit(FORM_NAME);
  };

  handleSubmitForm = values => {
    const { fetchStart, fetchEnd, showNotification, selectedIds } = this.props;
    fetchStart();
    HttpClient(`${Config.api.HOST}/time_series/export`, {
      method: "POST",
      body: JSON.stringify({
        device_ids: selectedIds,
        range: values.reporting_interval
      })
    })
      .then(data => {
        window.open(`${Config.api.HOST}${data.json.path}`, "_blank");
        this.props.reset(FORM_NAME);
        this.setState({ showFormDialog: false });
      })
      .catch(error => {
        showNotification(error.message, "error");
      })
      .finally(() => {
        this.setState({ isSubmitting: false });
        fetchEnd();
      });
  };
  render() {
    const { showFormDialog, isSubmitting } = this.state;
    const { actionLabel, classes, isValid, selectedIds } = this.props;

    return (
      <>
        <Button color="primary" onClick={this.handleShowForm}>
          <ExportIcon
            className={classNames(classes.leftIcon, classes.iconSmall)}
          />
          {actionLabel}
        </Button>

        {showFormDialog && (
          <XlsFormDialog
            handleClose={this.handleCloseForm}
            handleSubmit={this.handleSubmit}
            handleSubmitForm={this.handleSubmitForm}
            isOpen={showFormDialog}
            isSubmitting={isSubmitting}
            isValid={isValid}
            reduxFormName={FORM_NAME}
            selectedIds={selectedIds}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  isValid: isValid(FORM_NAME)(state)
});

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  reset
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(bulkIconStyles)(MonitorXlsExport));
