import React from "react";

import { NumberInput, number } from "react-admin";
import { extractRest } from "../../../../utils/extractRest";

const BulkEditFormFields = props => {
  const restProps = extractRest(props);
  return (
    <div className="form-fields">
      <div className="form-row">
        <div className="w-100 w-45-m">
          <NumberInput
            label="Lower bound"
            source="valchange_alert[lower_bound]"
            validate={number()}
            helperText="Empty field means ‘-infinity’."
            fullWidth
            {...restProps}
          />
        </div>
        <div className="w-100 w-45-m">
          <NumberInput
            label="Upper bound"
            source="valchange_alert[upper_bound]"
            validate={number()}
            helperText="Empty field means ‘+infinity’."
            fullWidth
            {...restProps}
          />
        </div>
      </div>
    </div>
  );
};

export default BulkEditFormFields;
