import React from "react";
import moment from "moment";
import { DISPLAY_TIME_FORMAT } from "../ChartDataController";
import TemphumidTemperatureChart from "./TemphumidTemperatureChart";
import TemphumidHumidityChart from "./TemphumidHumidityChart";

export default function TemphumidChartContainer({ data, loadData, params, bucket }) {
  const [ruleT, ruleH] = params.alert_rules || [];
  const { lower_bound: lbT, upper_bound: ubT } = ruleT || {};
  const { lower_bound: lbH, upper_bound: ubH } = ruleH || {};

  const chartData = data.map(d => {
    return {
      bucket: d.bucket,
      time: moment(d.time).format(DISPLAY_TIME_FORMAT),
      Tave: d.tave,
      Tend: d.tmom !== d.tave ? d.tmom : null,
      Have: d.have,
      Hend: d.hmom !== d.have ? d.hmom : null,
      "Lower bound T": lbT,
      "Upper bound T": ubT,
      "Lower bound H": lbH,
      "Upper bound H": ubH
    };
  });

  return (
    <div style={{ width: "99%" }}>
      <TemphumidTemperatureChart
        height={205}
        data={chartData}
        loadData={loadData}
        canZoomIn={bucket >= params.measure_interval}
      />
      <TemphumidHumidityChart
        height={205}
        data={chartData}
        loadData={loadData}
        canZoomIn={bucket >= params.measure_interval}
      />
    </div>
  );
}
