function extractTime(obj = {}) {
  return obj.time ? obj.time : "";
}

function sumSerieValues(series, serieName) {
  return series.reduce((sum, record) => sum + record[serieName], 0);
}

function extractSerieValues(series, serieName) {
  return series.map(record => record[serieName]);
}

function avgAggregation(series = [], serieName) {
  if (series.length === 0) return null;
  return sumSerieValues(series, serieName) / series.length;
}

function minAggregation(series = [], serieName) {
  if (series.length === 0) return null;
  return Math.min(...extractSerieValues(series, serieName));
}

function maxAggregation(series = [], serieName) {
  if (series.length === 0) return null;
  return Math.max(...extractSerieValues(series, serieName));
}

function lastAggregation(series = [], serieName) {
  if (series.length === 0) return null;
  return extractSerieValues(series, serieName)[series.length - 1];
}

// function aggregateBasicIncrementalValues(newRecords, currentValues) {
//   if (newRecords.length === 0) return null;
//   const time = extractTime(currentValues);
//   // last record by time with non empty value
//   const result = newRecords.filter(vals => vals.time > time && vals.value !== null).slice(-1)[0];
//   if (result) return { time: result.time, values: [result.value] };
//   return null;
// }

function seriesAggregator(
  currentValues,
  seriesRecords = [],
  seriesNames = [],
  aggregations = [],
  recordFilter = record => record[seriesNames[0]] !== null,
  time = extractTime(currentValues),
  noNullValues = false
) {
  if (seriesRecords.length === 0 || seriesNames.length === 0 || aggregations === 0) return null;
  const nonEmptyRecords = seriesRecords.filter(recordFilter);
  if (noNullValues && nonEmptyRecords.length === 0) return null;
  const [newerTimeRecord] = nonEmptyRecords.filter(record => record.time > time).slice(-1);
  return {
    time: newerTimeRecord ? newerTimeRecord.time : time,
    values: seriesNames.map((serieName, i) => aggregations[i](nonEmptyRecords, serieName))
  };
}

function aggregateTemperatureValues(newRecords, currentValues) {
  return seriesAggregator(
    currentValues,
    newRecords,
    ["tave", "tmin", "tmax"],
    [avgAggregation, minAggregation, maxAggregation]
  );
}

function aggregateTemphumidValues(newRecords, currentValues) {
  return seriesAggregator(
    currentValues,
    newRecords,
    ["tave", "tmom", "have", "hmom"],
    [avgAggregation, lastAggregation, avgAggregation, lastAggregation]
  );
}

function aggregateTemphumidco2Values(newRecords, currentValues) {
  return seriesAggregator(
      currentValues,
      newRecords,
      ["temperature", "temperaturemom", "humidity", "humiditymom", "co2", "co2mom"],
      [avgAggregation, lastAggregation, avgAggregation, lastAggregation, avgAggregation, lastAggregation]
  );
}

function aggregateBasicIncrementalValues(newRecords, currentValues) {
  let time = extractTime(currentValues);
  return seriesAggregator(
    currentValues,
    newRecords,
    ["value"],
    [lastAggregation],
    // custom filter for values to be aggregated (candidates)
    record => record.time > time && record.value !== null,
    // so it can be used if calculeted already
    time,
    // stop aggregating if no candidates
    true
  );
}

export function aggregatorFactory(message_type) {
  if (message_type === "temperature") return aggregateTemperatureValues;
  if (message_type === "temphumid") return aggregateTemphumidValues;
  if (message_type === "temphumidco2") return aggregateTemphumidco2Values;
  return aggregateBasicIncrementalValues;
}
