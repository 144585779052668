import React from "react";

import { FileField, FileInput } from "react-admin";

import { extractRest } from "../../../utils/extractRest";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  dropZone: {
    background: "#8a8a8a",
    cursor: "pointer",
    padding: "2rem 1rem",
    textAlign: "center",
    color: "#fff"
  }
});

const DeviceCsvImportFormFields = props => {
  const restProps = extractRest(props);
  return (
    <div className="form-fields">
      <div className="form-row">
        <div className="w-100">
          <FileInput
            className="m-0"
            source="csv"
            label=""
            accept="text/csv"
            placeholder={
              <p className="m-0">
                Drop CSV file here or click to select CSV file to upload{" "}
              </p>
            }
            options={{
              // props przekazywany do Dropzone (react-dropzone) przez operator spread
              className: props.classes.dropZone
            }}
            {...restProps}
          >
            <FileField source="src" title="title" />
          </FileInput>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(DeviceCsvImportFormFields);
