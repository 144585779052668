import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Button,
  Paper,
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import HttpClient from "../HttpClient";
import Config from "../Config";
import AuthLayout from "./AuthLayout";
import AuthNotification from "./AuthNotification";

const FAILURE_MESSAGE = "This account already exists";
const PASSWORD_HELPER_TEXT =
  "Password must contain at least 6 characters, including uppercase/lowercase letters and numbers";
const PASSWORD_FAILURE_MESSAGE = "Password and confirmation did not match";
const SIGN_UP_SUCCESS_MESSAGE =
  "Successfully registered new account, confirmation email was sent";

class SignUpPage extends Component {
  notifRef = React.createRef();

  state = {
    email: "",
    password: "",
    passwordConfirmation: "",
    termsChecked: true,
    error: false,
    success: false
  };

  handleChange = ({ target }) => {
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      [target.id]: value
    });
  };

  submit = e => {
    e.preventDefault();
    const {
      email,
      password: pwd,
      passwordConfirmation: pwd_confirmation,
      termsChecked: terms_agreement
    } = this.state;

    if (pwd !== pwd_confirmation) {
      this.setState(
        _state => ({ error: true }),
        () =>
          this.notifRef.current.setNotification({
            type: "error",
            message: PASSWORD_FAILURE_MESSAGE
          })
      );
    } else {
      HttpClient(Config.api.HOST + "/sign_up", {
        method: "POST",
        body: JSON.stringify({ email, pwd, pwd_confirmation, terms_agreement })
      })
        .then(result => {
          this.setState({ success: true });
        })
        .catch(error => {
          this.notifRef.current.setNotification({
            type: "error",
            message: FAILURE_MESSAGE
          });
        });
    }
  };

  render() {
    const {
      success,
      email,
      error,
      password,
      passwordConfirmation
    } = this.state;

    return (
      <>
        {success && (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                notification: {
                  type: "success",
                  message: SIGN_UP_SUCCESS_MESSAGE
                }
              }
            }}
          />
        )}
        <AuthLayout snackbar={<AuthNotification ref={this.notifRef} />}>
          <Paper>
            <Typography component="h1" variant="display1">
              Register
            </Typography>
            <form onSubmit={this.submit}>
              <TextField
                id="email"
                label="E-mail"
                type="email"
                value={email}
                onChange={this.handleChange}
                margin="normal"
                fullWidth
                required
                autoFocus={true}
                error={error}
              />
              <TextField
                id="password"
                label="Password"
                type="password"
                value={password}
                onChange={this.handleChange}
                margin="normal"
                fullWidth
                required
                error={error}
                inputProps={{
                  pattern: "(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])\\S{6,}"
                }}
                helperText={PASSWORD_HELPER_TEXT}
              />
              <TextField
                id="passwordConfirmation"
                label="Repeat password"
                type="password"
                value={passwordConfirmation}
                onChange={this.handleChange}
                margin="normal"
                fullWidth
                required
                error={error}
                inputProps={{
                  pattern: "(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])\\S{6,}"
                }}
              />
              <FormControl
                error={error}
                style={{ margin: "10px 0" }}
                component="fieldset"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      required
                      id="termsChecked"
                      checked={this.state.termsChecked}
                      onChange={this.handleChange}
                      value="1"
                      color="primary"
                    />
                  }
                  label="By using this application I accept the relevant terms nad
                    conditions provided on IOTA Reader website."
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Register
              </Button>
              <Button
                fullWidth
                variant="outlined"
                component={Link}
                to="/login"
                color="primary"
              >
                Sign in
              </Button>
            </form>
          </Paper>
        </AuthLayout>
      </>
    );
  }
}

export default SignUpPage;
