import React from "react";
import { connect } from "react-redux";
import { change, submit, isSubmitting, isValid, reset } from "redux-form";
import { fetchEnd, fetchStart, showNotification, REDUX_FORM_NAME } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { refreshView as refreshViewAction } from "ra-core";

import Button from "@material-ui/core/Button";
import OpenIcon from "@material-ui/icons/Edit";

import HttpClient from "../../../../HttpClient";
import Config from "../../../../Config";
import BulkFormDialog from "./BulkEditFormDialog";
import prepareAlertRules from "../../MonitorCreate/prepareAlertRules";

export const bulkIconStyles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  }
});

class MonitorBulkEdit extends React.Component {
  state = {
    showFormDialog: false
  };

  handleShowForm = () => {
    this.setState({ showFormDialog: true });
  };

  handleCloseForm = () => {
    this.props.reset(REDUX_FORM_NAME);
    this.setState({ showFormDialog: false });
  };

  handleSubmit = () => {
    const { submit } = this.props;
    submit(REDUX_FORM_NAME);
  };

  handleSubmitForm = values => {
    const { fetchStart, fetchEnd, showNotification, selectedIds } = this.props;
    fetchStart();
    HttpClient(`${Config.api.HOST}/rpc/bulk_update_monitors`, {
      method: "POST",
      body: JSON.stringify({
        ids: selectedIds,
        new_values: prepareAlertRules(values)
      })
    })
      .then(() => {
        this.props.reset(REDUX_FORM_NAME);
        this.setState({ showFormDialog: false });
        this.props.refreshView();
      })
      .catch(error => {
        showNotification(error.message, "error");
      })
      .finally(() => {
        fetchEnd();
      });
  };

  render() {
    const { showFormDialog } = this.state;
    const { isSubmitting, isValid, selectedIds, actionLabel, classes } = this.props;

    return (
      <>
        <Button color="primary" onClick={this.handleShowForm}>
          <OpenIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
          {actionLabel}
        </Button>

        {showFormDialog && (
          <BulkFormDialog
            handleCloseForm={this.handleCloseForm}
            handleSubmit={this.handleSubmit}
            handleSubmitForm={this.handleSubmitForm}
            isOpen={showFormDialog}
            isSubmitting={isSubmitting}
            isValid={isValid}
            reduxFormName={REDUX_FORM_NAME}
            selectedIds={selectedIds}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting(REDUX_FORM_NAME)(state),
  isValid: isValid(REDUX_FORM_NAME)(state)
});

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  reset,
  refreshView: refreshViewAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(bulkIconStyles)(MonitorBulkEdit));
