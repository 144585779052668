import React from "react";
import IotaDialog from "../../../ra-ui-iota/IotaDialog";
import IotaSaveButton from "../../../ra-ui-iota/IotaSaveButton";

const DeleteDialog = ({ handleClose, handleDelete, isOpen, title, children }) => (
  <IotaDialog
    isOpen={isOpen}
    onClose={handleClose}
    dialog={{
      title: title,
      cancel: "Cancel",
      maxWidth: "sm"
    }}
    save={<IotaSaveButton color="primary" onClick={handleDelete} label={"Delete"} />}
  >
    {children}
  </IotaDialog>
);

export default DeleteDialog;
