import React from "react";
import IotaDialog from "../../../ra-ui-iota/IotaDialog";
import IotaForm from "../../../ra-ui-iota/IotaForm";
import IotaSaveButton from "../../../ra-ui-iota/IotaSaveButton";
import DeviceBulkFormFields from "./DeviceBulkFormFields";
import validateDeviceFormat from "../ValidateDeviceFormat";

const BulkFormDialog = ({
  isOpen,
  handleCloseForm,
  isValid,
  handleShowConfirmation,
  selectedIds,
  reduxFormName,
  handleSubmitForm
}) => (
  <IotaDialog
    isOpen={isOpen}
    onClose={handleCloseForm}
    dialog={{
      title: "Edit selected",
      cancel: "Cancel"
    }}
    save={
      <IotaSaveButton
        color="primary"
        disabled={!isValid}
        onClick={handleShowConfirmation}
        label={"Save"}
      />
    }
  >
    <p className="m-0">
      You are editing selected devices:{" "}
      <strong style={{color: "black"}}>{selectedIds.join(", ")}</strong>
    </p>
    {isOpen && (
      <IotaForm
        validate={validateDeviceFormat}
        form={reduxFormName}
        resource="devices"
        onSubmit={handleSubmitForm}
        toolbar={null}
        forceUnregisterOnUnmount={true}
      >
        <DeviceBulkFormFields resource="devices" />
      </IotaForm>
    )}
  </IotaDialog>
);

export default BulkFormDialog;
