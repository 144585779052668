const DEVICE_PARAMETERS_TYPES = {
  "device id": String,
  psc: String,
  name: String,
  "unit of measure": String,
  "meter type": String,
  multiplier: Number,
  shift: Number,
  "transmit interval": String,
  "data format type": String,
  // basic fields
  "value length": Number,
  "number of values": Number,
  // incremental fields
  "counter length": Number,
  "increment length": Number,
  "number of increments": Number
};

export const DEVICE_PARAMETERS_KEYS = {
  "device id": ["id"],
  psc: ["psc"],
  name: ["name"],
  "unit of measure": ["uom"],
  "meter type": ["meter_type"],
  multiplier: ["multiplier"],
  shift: ["shift"],
  "transmit interval": ["transmit_interval"],
  "data format type": ["message_format", "type"],
  // basic fields
  "value length": ["message_format", "value_bytes"],
  "number of values": ["message_format", "value_count"],
  // incremental fields
  "counter length": ["message_format", "value_bytes"],
  "increment length": ["message_format", "increment_bytes"],
  "number of increments": ["message_format", "increment_count"]
};

const mapDeviceParameters = data => {
  return Object.keys(data).reduce(
    (deviceParameters, currentKey, index, array) => {
      const value = data[currentKey];
      const trimmedValue = value.trim();
      const trimmedKey = currentKey.trim().toLowerCase();
      const newKey = DEVICE_PARAMETERS_KEYS[trimmedKey] || [];

      if (trimmedValue.length > 0) {
        if (newKey.length === 1) {
          deviceParameters[newKey[0]] = DEVICE_PARAMETERS_TYPES[trimmedKey](
            trimmedValue
          );
        } else if (newKey.length === 2) {
          if (typeof deviceParameters[newKey[0]] === "undefined") {
            deviceParameters[newKey[0]] = {};
          }
          deviceParameters[newKey[0]][newKey[1]] = DEVICE_PARAMETERS_TYPES[
            trimmedKey
          ](trimmedValue);
        }
      }
      return deviceParameters;
    },
    {}
  );
};

export default mapDeviceParameters;
