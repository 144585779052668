import React from "react";
import { Admin, Resource, Layout, Sidebar, Notification } from "react-admin";
import { createMuiTheme, withStyles } from "@material-ui/core/styles";
import NotificationsIcon from "@material-ui/icons/Notifications";
import DevicesIcon from "@material-ui/icons/Settings";
import englishMessages from "ra-language-english";

import postgrestClient from "./libs/PostgrestClient";
import Config from "./Config";
import HttpClient from "./HttpClient";
import AuthProvider, { isAllowed } from "./AuthProvider";
import LoginPage from "./auth/LoginPage";
import CustomRoutes from "./CustomRoutes";
import domainMessages from "./i18n";

import IotaMenu from "./ra-ui-iota/IotaMenu";
import IotaAppBar from "./ra-ui-iota/IotaAppBar";

import { TimeSeriesDataList } from "./sections/time_series_data/list";
import { DevicesList } from "./sections/devices/list";

import { DeviceCreate } from "./sections/devices/create";
import { DeviceEdit } from "./sections/devices/edit";
import MonitorList from "./sections/monitors/MonitorList";
import { NotificationsList } from "./sections/notifications/list";
import MonitorsIcon from "./icons/BarChart";

import "./App.css";

const sidebarStyles = theme => ({
  drawerPaper: {
    backgroundColor: "#34559b",
    marginTop: 0,
    paddingTop: 0,
    height: "100%",
    minHeight: "100vh"
  }
});

const layoutStyles = theme => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      minWidth: "initial"
    }
  },
  appFrame: {
    display: "block"
  },
  content: {
    display: "block",
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 15,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 8,
      paddingRight: 8,
      paddingBottom: 0
    }
  },
  contentWithSidebar: {
    [theme.breakpoints.down("xs")]: {
      display: "block"
    }
  }
});

const IotaSidebar = withStyles(sidebarStyles)(({ classes, ...props }) => (
  <Sidebar classes={classes} {...props} size={180} />
));

const IotaLayout = withStyles(layoutStyles)(({ classes, ...props }) => (
  <Layout
    {...props}
    classes={classes}
    appBar={IotaAppBar}
    sidebar={IotaSidebar}
    menu={IotaMenu}
    notification={Notification}
  />
));

const COLOR_SECONDARY = "#3DA092";

// const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#34559B"
    },
    secondary: {
      main: COLOR_SECONDARY
    },
    text: {
      menuItem: "white"
    }
  },
  overrides: {
    MuiDialog: {
      paper: {
        "@media (max-width: 600px)": {
          margin: "48px 24px"
        }
      }
    },
    MuiDialogTitle: {
      root: {
        background: "#fafafa"
      }
    },
    MuiDialogContent: {
      root: {
        padding: 24,
        color: "rgba(0, 0, 0, 0.54)"
      }
    },
    MuiDialogActions: {
      root: {
        margin: 0,
        padding: "8px 4px"
      }
    },
    MuiButton: {
      root: {
        textTransform: "initial",
        "@media (max-width: 600px)": {
          fontSize: "0.675rem"
        }
      }
    },
    MuiFormLabel: {
      root: {
        "&$focused": { color: COLOR_SECONDARY }
      },
      focused: {}
    },
    MuiInput: {
      underline: {
        "&:after": { borderBottomColor: COLOR_SECONDARY }
      }
    },
    MuiToolbar: {
      root: {
        position: "relative !important",
        height: "initial !important"
      },
      dense: {
        minHeight: "64px"
      }
    },
    MuiAppBar: {
      colorSecondary: { backgroundColor: "#313030" }
    }
  }
});

const messages = {
  en: { ...englishMessages, ...domainMessages.en }
};
const i18nProvider = locale => messages[locale];

const App = () => (
  <Admin
    theme={theme}
    appLayout={IotaLayout}
    authProvider={AuthProvider}
    loginPage={LoginPage}
    customRoutes={CustomRoutes}
    dataProvider={postgrestClient(Config.api.HOST, HttpClient)}
    i18nProvider={i18nProvider}
  >
    {role => {
      if (!role) return null;
      const canUserSee = isAllowed(role);
      return [
        <Resource
          options={{ label: "Charts" }}
          name="monitors"
          list={MonitorList}
          icon={MonitorsIcon}
        />,
        <Resource
          icon={NotificationsIcon}
          options={{ label: "Alerts" }}
          name="time_series_alerts"
          list={NotificationsList}
        />,
        canUserSee("devices") ? (
          <Resource
            name="devices"
            options={{ label: "Devices" }}
            list={DevicesList}
            create={DeviceCreate}
            edit={DeviceEdit}
            icon={DevicesIcon}
          />
        ) : null,
        canUserSee("time_series_data") ? (
          <Resource
            name="time_series_data"
            options={{ label: "Data log" }}
            list={TimeSeriesDataList}
          />
        ) : null,
        <Resource name="meter_types" />,
        canUserSee("transmit_intervals") ? <Resource name="transmit_intervals" /> : null
      ];
    }}
  </Admin>
);

export default App;
