import React from "react";

import {
  Bar,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ComposedChart,
  ResponsiveContainer,
  ReferenceArea,
  Label
} from "recharts";

import { XAxisTick, YAxisTick } from "../axisTicks";
import ChartZoomController from "../ChartZoomController";
import { tooltipFormatter } from "../tooltipFormatter";

const BAR_WIDTH = 10;
const PADDING = 0.01;
const OFFSET = 0.5;

class ValueChangeRateChart extends React.Component {
  getYScale = data => {
    const bars = data.filter(v => v !== undefined && v !== null);
    let barMax = Math.max(...bars);
    let barMin = Math.min(...bars);
    let barRange = barMax - barMin;
    if (barMin > 0) {
      // all values positive, min should not be below 0
      barMin = Math.max(barMin - barRange * OFFSET, 0);
      barMax += barRange * PADDING;
    } else if (barMax < 0) {
      // all values negative, max should not be above 0
      barMin -= barRange * PADDING;
      barMax = Math.min(barMax + barRange * OFFSET, 0);
    } else {
      // barMin negative, barMax positive
      barMin -= barRange * PADDING;
      barMax += barRange * PADDING;
    }
    return [barMin, barMax];
  };

  render() {
    const { range = "4_hours", data, height, canZoomIn, loadData, message_type } = this.props;
    const [barMin, barMax] = this.getYScale(data.map(d => d["Rate"]));
    let barYTicks = [];
    if (barMin < 0 && barMax > 0) {
      barYTicks = [barMin, 0, barMax];
    }
    return (
      <ChartZoomController data={data} loadData={loadData} canZoomIn={canZoomIn}>
        {({ zoomAxis, ...mouseEventsHandlres }) => (
          <ResponsiveContainer height={height}>
            <ComposedChart
              data={data}
              margin={{ top: 10, right: 0, left: 20, bottom: 40 }}
              style={{ fontSize: "0.7rem" }}
              {...mouseEventsHandlres}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                padding={{ left: 20, right: 20 }}
                allowDataOverflow={true}
                dataKey="time"
                domain={["dataMin", "dataMax"]}
                type="category"
                hide={true}
                tick={<XAxisTick range={range} />}
              />
              <YAxis
                // padding={{ top: 20 }}
                allowDataOverflow={true}
                dataKey="Rate"
                domain={[barMin, barMax]}
                // domain={["dataMin", "dataMax"]}
                type="number"
                yAxisId="1"
                ticks={barYTicks}
                tick={<YAxisTick format={"0,0.00"} textAnchor="end" />}
              >
                <Label value={message_type === "basic" ? "△" : "△/h"} offset={0} position="top" />
              </YAxis>
              <Tooltip formatter={tooltipFormatter} />

              <Bar
                yAxisId="1"
                type="linear"
                dataKey="Rate"
                fill="#7DC6BC"
                animationDuration={200}
                barSize={BAR_WIDTH}
              />
              <Line
                dot={false}
                yAxisId="1"
                type="linear"
                dataKey="Lower bound"
                stroke="#f87b18"
                strokeWidth="2"
                animationDuration={200}
                strokeDasharray="3 3"
              />
              <Line
                dot={false}
                yAxisId="1"
                type="linear"
                dataKey="Upper bound"
                stroke="#f44336"
                strokeWidth="2"
                animationDuration={200}
                strokeDasharray="3 3"
              />
              {zoomAxis[0] && zoomAxis[1] ? (
                <ReferenceArea yAxisId="1" x1={zoomAxis[0]} x2={zoomAxis[1]} strokeOpacity={0.3} />
              ) : null}
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </ChartZoomController>
    );
  }
}

export default ValueChangeRateChart;
