import React from "react";
import IotaDialog from "../../../ra-ui-iota/IotaDialog";
import IotaSaveButton from "../../../ra-ui-iota/IotaSaveButton";

const BulkConfirmDialog = ({
  isOpen,
  handleCloseConfirmation,
  handleConfirm,
  isValid,
  isSubmitting
}) => (
  <IotaDialog
    isOpen={isOpen}
    onClose={handleCloseConfirmation}
    dialog={{
      open: "Save",
      title: "Please confirm changes",
      cancel: "Cancel",
      maxWidth: "sm"
    }}
    save={
      <IotaSaveButton
        onClick={handleConfirm}
        disabled={!isValid}
        label={"Save"}
        saving={isSubmitting}
      />
    }
  >
    {"Are you sure you want to save these changes?"}
  </IotaDialog>
);

export default BulkConfirmDialog;
