import { formattedValue } from "../MonitorList/Meter/ValueWithLabel";

export const tooltipValueFormatter = {
  Tave: value => formattedValue(value, 1, "°"),
  Tmax: value => formattedValue(value, 1, "°"),
  Tmin: value => formattedValue(value, 1, "°"),
  Tmom: value => formattedValue(value, 1, "°"),
  Tend: value => formattedValue(value, 1, "°"),
  Have: value => formattedValue(value, 0, "%"),
  Hend: value => formattedValue(value, 0, "%"),
  Hmom: value => formattedValue(value, 0, "%"),
  Rate: value => formattedValue(value, 4, ""),
  Value: value => tenCharacterNumber(value)
};

export const tooltipFormatter = (value, name, props) => {
  const formatter = tooltipValueFormatter[name];
  return formatter ? formatter(value) : value;
};

export function numberWithMaxCharacters(len) {
  return function(value = null) {
    if (value === null) return value;
    const valueString = String(value);
    if (valueString.length <= len) return value;
    const [whole, frac] = valueString.split(".");
    if (!frac) return value;
    const digits = Math.max(len - whole.length - 1, 0); //  -1 because of decimal point
    return Number(value.toFixed(digits)); // back to Number to drop the non important zeros
  };
}
export const tenCharacterNumber = numberWithMaxCharacters(10);

export default tooltipValueFormatter;
