import React from 'react';
import { Route } from 'react-router-dom';
import SignUpPage from './auth/SignUpPage';
import ActivationPage from './auth/ActivationPage';
import PasswordForgottenPage from './auth/PasswordForgottenPage';
import ChangePasswordPage from './auth/ChangePasswordPage';
import RegisterViewerPage from './auth/RegisterViewerPage';
import LoginViewerPage from './auth/LoginViewerPage';

export default [
  <Route exact path="/sign_up" component={SignUpPage} noLayout />,
  <Route exact path="/activation/:token" component={ActivationPage} noLayout />,
  <Route exact path="/password_forgotten" component={PasswordForgottenPage} noLayout />,
  <Route exact path="/change_password/:token" component={ChangePasswordPage} noLayout />,
  <Route exact path="/register_viewer" component={RegisterViewerPage} noLayout />,
  <Route exact path="/login_viewer" component={LoginViewerPage} noLayout />,
];
