import React from "react";
import IotaDialog from "../../../ra-ui-iota/IotaDialog";
import IotaForm from "../../../ra-ui-iota/IotaForm";
import IotaSaveButton from "../../../ra-ui-iota/IotaSaveButton";
import DeviceCsvImportFormFields from "./DeviceCsvImportFormFields";
import validateImportFormat from "./validateImportFormat";

const CsvImportFormDialog = ({
  isOpen,
  handleClose,
  isValid,
  handleShowConfirmation,
  selectedIds,
  reduxFormName,
  handleSubmitForm,
  handleSubmit
}) => (
  <IotaDialog
    isOpen={isOpen}
    onClose={handleClose}
    dialog={{
      title: "Register new multiple devices from CSV",
      cancel: "Cancel"
    }}
    save={
      <IotaSaveButton
        color="primary"
        disabled={!isValid}
        onClick={handleSubmit}
        label={"Import"}
      />
    }
  >
    {isOpen && (
      <IotaForm
        validate={validateImportFormat}
        form={reduxFormName}
        resource="devices"
        onSubmit={handleSubmitForm}
        toolbar={null}
      >
        <DeviceCsvImportFormFields />
      </IotaForm>
    )}
  </IotaDialog>
);

export default CsvImportFormDialog;
