export const themeExtension = {
  palette: {
    tertiary: {
      main: "#3DA092",
      dark: '#00695C'
    }
  },
  tertiaryButton: {
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    backgroundColor: "#3DA092",
    color: '#fff',
    '&:hover': {
      backgroundColor: '#10796B',
    }
  },
};
