import React from "react";
import moment from "moment";
import { DISPLAY_TIME_FORMAT } from "../../Charts/ChartDataController";
import { tenCharacterNumber } from "../../Charts/tooltipFormatter";

export default function BasicIncrementalMeter({ device_id, uom, aggregate }) {
  return (
    <div className="absolute-container">
      <div className="flex-cent meter-device-id">{device_id}</div>
      {aggregate.values !== null ? (
        <>
          <div className="flex-cent basic-data">
            <div className="meter-value">
              <span style={{ letterSpacing: "0.6vw" }}>
                {tenCharacterNumber(aggregate.values[0])}
              </span>
              <span
                style={{
                  letterSpacing: "initial",
                  marginLeft: "5px"
                }}
              >
                {uom}
              </span>
            </div>
          </div>
          <div className="flex-cent meter-time">
            {moment(aggregate.time).format(DISPLAY_TIME_FORMAT)}
          </div>
        </>
      ) : (
        <div className="basic-data meter-no-value">no data</div>
      )}
    </div>
  );
}
