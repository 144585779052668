import React from "react";
// import MonitorBulkEdit from "./MonitorBulkEdit";
import MonitorXlsExport from "./MonitorXlsExport";

const MonitorBulkActions = props => (
  <>
    {/* <MonitorBulkEdit actionLabel="Edit selected" {...props} /> */}
    <MonitorXlsExport actionLabel="Export to XLSX" {...props} />
  </>
);

export default MonitorBulkActions;
