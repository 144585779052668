import React from "react";
import { CardActions, CreateButton } from "react-admin";

import DeviceCsvImport from "./DeviceCsvImport";

const Actions = ({
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton
      basePath={basePath}
      variant="extendedFab"
      style={{
        minHeight: "initial",
        height: "32px",
        marginRight: "16px",
        padding: "0 16px"
      }}
    />
    <DeviceCsvImport actionLabel="Import" />
  </CardActions>
);

export default Actions;
