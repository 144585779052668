import React from "react";
import { Filter, ReferenceInput, TextInput } from "react-admin";
import { StyledSelectInput } from "../monitors/MonitorList/MonitorFilter";

const DeviceFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="fts.search_query" alwaysOn />
    <ReferenceInput
      label="Meter type"
      source="meter_type"
      reference="meter_types"
      allowEmpty
      alwaysOn
    >
      <StyledSelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export default DeviceFilter;
