import React from "react";
import IotaDialog from "../../../../ra-ui-iota/IotaDialog";
import IotaForm from "../../../../ra-ui-iota/IotaForm";
import IotaSaveButton from "../../../../ra-ui-iota/IotaSaveButton";
import MonitorBulkFormFields from "./BulkEditFormFields";
import validateBoundingValues from "../../MonitorEdit/validateBoundingValues";

const BulkEditFormDialog = ({
  handleCloseForm,
  handleSubmit,
  handleSubmitForm,
  isOpen,
  isSubmitting,
  isValid,
  reduxFormName,
  selectedIds
}) => (
  <IotaDialog
    isOpen={isOpen}
    onClose={handleCloseForm}
    dialog={{
      title: "Edit selected",
      cancel: "Cancel"
    }}
    save={
      <IotaSaveButton
        color="primary"
        disabled={!isValid}
        onClick={handleSubmit}
        label={"Save"}
        saving={isSubmitting}
      />
    }
  >
    <p className="m-0">
      You are editing selected monitored devices:{" "}
      <strong style={{ color: "black" }}>{selectedIds.join(", ")}</strong>
    </p>
    <p>Define lower and upper values for value change alerts.</p>
    {isOpen && (
      <IotaForm
        validate={validateBoundingValues}
        form={reduxFormName}
        resource="devices"
        onSubmit={handleSubmitForm}
        toolbar={null}
        forceUnregisterOnUnmount={true}
      >
        <MonitorBulkFormFields />
      </IotaForm>
    )}
  </IotaDialog>
);

export default BulkEditFormDialog;
