import React from "react";
import {
  createMuiTheme,
  MuiThemeProvider,
  CssBaseline
} from "@material-ui/core";

const COLOR_PRIMARY = "#34559b";

const defaultTheme = createMuiTheme();
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: COLOR_PRIMARY
    },
    secondary: {
      // main: "#313030"
      main: "#3DA092"
    },
    tertiary: {
      main: "#3DA092",
      dark: "#00695C"
    }
  },
  overrides: {
    MuiGrid: {
      container: {
        minHeight: "100vh",
        padding: defaultTheme.spacing.unit * 2,
        [defaultTheme.breakpoints.up("sm")]: {
          padding: defaultTheme.spacing.unit * 3
        },
        // background: "linear-gradient(309deg, #4b0e8d, #13133d, #791ddc, #06dfd9)",
        background: "linear-gradient(200deg, #3DA092, #34559b)",
        backgroundSize: "120% 120%",
        animation: "GradientAnimation 10s ease infinite" // animation defined in App.css
      }
    },
    MuiPaper: {
      root: {
        padding: "24px 16px",
        maxWidth: "580px",
        [defaultTheme.breakpoints.up("sm")]: {
          padding: "40px 56px",
          width: "580px",
        }
      }
    },
    MuiButton: {
      root: {
        marginTop: defaultTheme.spacing.unit * 1.5
      },
      containedPrimary: {
        "&:hover": {
          backgroundColor: "#0F2C6B"
        }
      },
      containedSecondary: {
        "&:hover": {
          backgroundColor: "#10796B"
        }
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "0.75rem",
        lineHeight: "1.1"
      }
    }
  }
});

export default function GuestThemeProvider(props) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </MuiThemeProvider>
  );
}
