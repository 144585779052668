import React from "react";
import MonitorSortButton from "./MonitorSortButton";
import { Button } from "@material-ui/core";

const MonitorSort = ({ currentSort, setSort }) => (
  <div style={{display: "flex"}}>
    <Button style={{color: "#666", minWidth: "24px", padding: 0, justifyContent: "initial"}} disabled={true}>Sort:</Button>
    <MonitorSortButton
      label="Last Added"
      field="created_at"
      currentSort={currentSort}
      setSort={setSort}
    />
    <MonitorSortButton
      label="Alphabetical"
      field="name"
      currentSort={currentSort}
      setSort={setSort}
    />
  </div>
);

export default MonitorSort;
