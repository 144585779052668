import React from "react";
import AddToMonitoredDevices from "./AddToMonitoredDevices";
import RemoveFromMonitoredDevices from "./RemoveFromMonitoredDevices";

class AddRemoveMonitors extends React.Component {
  render() {
    const {
      record: { monitor_id }
    } = this.props;
    return monitor_id ? (
      <RemoveFromMonitoredDevices
        {...this.props}
        resource="monitors"
        dialog={{
          open: "Save",
          title: "Delete device from charts",
          description: "Do you want to delete this device from charts?",
          cancel: "Cancel",
          delete: "Delete",
          maxWidth: "sm"
        }}
      />
    ) : (
      <AddToMonitoredDevices {...this.props} />
    );
  }
}

export default AddRemoveMonitors;
