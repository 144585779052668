const isFormatType = (type, messageFormat) => {
  return messageFormat && messageFormat.type && messageFormat.type === type;
};

const validateDeviceFormat = values => {
  const errors = {};
  errors.message_format = {};

  const format = values.message_format;
  if (isFormatType('basic', format)) {
    if (format.value_count * format.value_bytes > 24) {
      errors.message_format.value_bytes = ['resulting format will be invalid'];
      errors.message_format.value_count = ['resulting format will be invalid'];
    }
  } else if (isFormatType('incremental', format)) {
    if (
      format.value_bytes + format.increment_count * format.increment_bytes >
      24
    ) {
      errors.message_format.value_bytes = ['resulting format will be invalid'];
      errors.message_format.increment_count = ['resulting format will be invalid'];
      errors.message_format.increment_bytes = ['resulting format will be invalid'];
    }
  }

  return errors;
};

export default validateDeviceFormat;
