export default function validateAlertRules(values) {
  const errors = validateBoundingValues(values, "alert_rule_0");
  if (values.alert_rule_1) Object.assign(errors, validateBoundingValues(values, "alert_rule_1"));
  return errors;
}

const validateBoundingValues = (values, ruleKey) => {
  const rule = values[ruleKey] || {};
  const { lower_bound, upper_bound } = rule;
  const errors = { [ruleKey]: {} };

  if (!!lower_bound && !!upper_bound && lower_bound >= upper_bound) {
    errors[ruleKey].lower_bound = ["lower bound cannot be higher than upper"];
  }

  return errors;
};
