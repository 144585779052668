import React from "react";
import DeviceAndTime from "./DeviceAndTime";
import ValueWithLabel, { labelWithSubscript, formattedValue } from "./ValueWithLabel";

export default function TemperatureMeter(props) {
  const {
    aggregate: { values = [] }
  } = props;
  const [tave, tmin, tmax] = values;

  return (
    <div className="absolute-container">
      <DeviceAndTime {...props} />
      <div className="temperature-data">
        <ValueWithLabel label={labelWithSubscript("T", "ave")} className="temperature-tave">
          <div>{formattedValue(tave, 1, "°")}</div>
        </ValueWithLabel>
        <div className="values-column">
          <ValueWithLabel label={labelWithSubscript("T", "max")} className="temperature-tmax">
            <div>{formattedValue(tmax, 1, "°")}</div>
          </ValueWithLabel>
          <ValueWithLabel label={labelWithSubscript("T", "min")} className="temperature-tmin">
            <div>{formattedValue(tmin, 1, "°")}</div>
          </ValueWithLabel>
        </div>
      </div>
    </div>
  );
}
