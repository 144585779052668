import React from "react";
import IotaDialog from "../../../ra-ui-iota/IotaDialog";
import DeviceUploader from "./DeviceUploader";

const CsvImportProgressDialog = ({
  isOpen,
  handleClose,
  isValid,
  handleShowConfirmation,
  selectedIds,
  reduxFormName,
  handleSubmitForm,
  handleSubmit,
  csvRows,
  csvFileName,
  csvError
}) => (
  <IotaDialog
    isOpen={isOpen}
    onClose={handleClose}
    dialog={{
      title: "Import from CSV file progress",
      cancel: "Close"
    }}
    save={null}
  >
    <>
      <div>
        <p>
          <span>Imported file: </span>
          {csvFileName}
        </p>
        <p>
          <span>Devices count: </span>
          {csvRows.length}
        </p>
      </div>
      {isOpen && csvError && <><p>CSV parsing error</p><p>{csvError}</p></>}
      {isOpen && !csvError && <DeviceUploader csvRows={csvRows} csvFileName={csvFileName} />}
    </>
  </IotaDialog>
);

export default CsvImportProgressDialog;
