import React, {Component} from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import {withStyles} from '@material-ui/core/styles';
import {Button} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
});

class CancelButton extends Component {
  handleClick = () => {
    const { push, target } = this.props;
    push(target);
  };

  render() {
    const { classes } = this.props;
    return (
      <Button
        onClick={this.handleClick}
        className={classes.button}
        color="primary"
        label="ra.action.cancel"
        >
        <IconCancel />
      </Button>
      );
  }
}
const StyledCancelButton = withStyles(styles)(CancelButton);

const mapDispatchToProps = {
  push
};

export default connect(
  null,
  mapDispatchToProps,
)(StyledCancelButton);
