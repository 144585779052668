import React from "react";

import {
  Toolbar,
  EditView,
  EditController,
  CardContentInner
} from "react-admin";
import { withStyles } from "@material-ui/core";

import validateDeviceFormat from "./ValidateDeviceFormat";
import SaveWithWarning from "./SaveWithWarning";
import CancelButton from "./CancelButton";
import SectionTitle from "../../utils/SectionTitle";
import IotaForm from "../../ra-ui-iota/IotaForm";
import DeviceFormFields from "./DeviceFormFields";
import { toolbarStyles } from "./create";



const DeviceEditToolbar = props => {
  return (
    <CardContentInner>
      <Toolbar {...props}>
        <SaveWithWarning
          redirect="list"
          submitOnEnter={false}
          variant="raised"
          dialog={{
            open: "Save",
            title: "Please confirm changes",
            description: "Are you sure you want to save these changes?",
            cancel: "Cancel",
            save: "Save",
            maxWidth: "sm"
          }}
        />
        <CancelButton target="/devices" />
      </Toolbar>
    </CardContentInner>
  );
};

const StyledEditToolbar = withStyles(toolbarStyles)(DeviceEditToolbar)

const DeviceTitle = ({ record }) => {
  return <span>Device {record ? `${record.name}` : ""}</span>;
};

export const DeviceEdit = props => (
  <>
    <SectionTitle title="Edit device data settings" />
    <EditController {...props}>
      {cp => (
        <EditView title={<DeviceTitle />} {...props} {...cp}>
          <IotaForm
            toolbar={<StyledEditToolbar />}
            validate={validateDeviceFormat}
          >
            <CardContentInner>
              <DeviceFormFields forEdit {...cp} />
            </CardContentInner>
          </IotaForm>
        </EditView>
      )}
    </EditController>
  </>
);

// TODO: refac. SimpleForm jest prawie takie samo jak w create
// export const DeviceEdit = props => (
//   <Edit title={<DeviceTitle />} {...props}>
//     <SimpleForm
//       toolbar={<DeviceEditToolbar />}
//       validate={validateDeviceFormat}>
//       <TextInput source="name" label="Device name" validate={required()} />
//       <DisabledInput source="id" label="ID" validate={required()} />
//       <DisabledInput source="pac" label="PAC" validate={required()} />
//       <ReferenceInput
//         label="Meter Type"
//         source="meter_type"
//         reference="meter_types"
//         validate={required()}>
//         <SelectInput optionText="name" />
//       </ReferenceInput>
//       <ReferenceInput
//         label="Transmit interval"
//         source="transmit_interval"
//         reference="transmit_intervals"
//         validate={required()}>
//         <SelectInput optionText="name" />
//       </ReferenceInput>

//       <RadioButtonGroupInput
//         source="message_format[type]"
//         label="Data format"
//         validate={choices(['basic', 'incremental'], 'is required')}
//         defaultValue={() => 'basic'}
//         choices={[
//           {id: 'basic', name: 'Simple'},
//           {id: 'incremental', name: 'Incremental'},
//         ]}
//       />
//       <label>
//         <strong>Data format</strong>
//       </label>
//       <NumberInput
//         source="message_format[value_bytes]"
//         label="Value bytes"
//         validate={[required(), number(), minValue(2), maxValue(24)]}
//       />

//       <FormDataConsumer>
//         {/*

//         redux is not clearing fields when @@redux-form/UNREGISTER_FIELD
//         https://github.com/erikras/redux-form/issues/2032

//         TODO: fix with own create action which can filter sent fields
//         FIX: https://marmelab.com/react-admin/Actions.html#altering-the-form-values-before-submitting

//         */}

//         {({formData, ...rest}) => {
//           return (
//             <div>
//               {formData.message_format &&
//                 formData.message_format.type === 'basic' && (
//                   <BasicFormatInputs {...rest} />
//                 )}
//               {formData.message_format &&
//                 formData.message_format.type === 'incremental' && (
//                   <IncrementalFormatInputs {...rest} />
//                 )}
//             </div>
//           );
//         }}
//       </FormDataConsumer>

//       <DisabledInput
//         label="Message length"
//         source="message_format[msg_length]"
//         type="number"
//         defaultValue={() => 24}
//       />

//       <label>
//         <strong>Value adjustments</strong>
//       </label>
//       <TextInput
//         source="multiplier"
//         label="Multiplier"
//         validate={[number(), minValue(1)]}
//       />
//       <TextInput
//         validate={[number(), minValue(0)]}
//         source="shift"
//         label="Shift"
//       />
//     </SimpleForm>
//   </Edit>
// );
