import React, { Component } from "react";
import { Button, Paper, Typography } from "@material-ui/core";

import { hideNotification, fetchEnd, fetchStart } from "react-admin";
import { change, submit, isSubmitting, reset } from "redux-form";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import AuthNotification from "./AuthNotification";

import HttpClient from "../HttpClient";
import Config from "../Config";
import sessionFromToken from "./sessionFromToken";

import { withStyles } from "@material-ui/core/styles";

import { TextInput, required, REDUX_FORM_NAME } from "react-admin";

import IotaForm from "../ra-ui-iota/IotaForm";
import IotaSaveButton from "../ra-ui-iota/IotaSaveButton";
import { extractRest } from "../utils/extractRest";
import { validatePsc } from "../validations/validatePsc";
import { themeExtension } from "../themeExtension";

const FAILURE_MESSAGE =
  "Device doesn't exist, please contact the device owner.";
const TOKEN_FAILURE_MESSAGE = "Access expired, please sign in again";

const styles = theme => ({
  pushedTop: {
    marginTop: theme.spacing.unit * 5
  },
  viewerButton: themeExtension.tertiaryButton
});

class RegisterViewerPage extends Component {
  notifRef = React.createRef();

  state = {
    device_id: "",
    psc: "",
    formSubmitted: false
  };

  handleSave = () => {
    const { submit } = this.props;
    submit(REDUX_FORM_NAME);
  };

  handleSubmit = values => {
    const { push } = this.props;
    this.setState({ formSubmitted: true });

    fetchStart();

    HttpClient(`${Config.api.HOST}/rpc/register_viewer`, {
      method: "POST",
      body: JSON.stringify(values),
      headers: new Headers({ Accept: "application/vnd.pgrst.object+json" })
    })
      .then(data => {
        sessionFromToken(data.json.token);
        push("/monitors");
      })
      .catch(error => {
        const message =
          error.message === "invalid_device" ? FAILURE_MESSAGE : error.message;

        this.notifRef.current.setNotification({
          type: "error",
          message: message
        });
        // this.setState({ formSubmitted: false });
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  // redirect 401 nie powoduje zwiększenia liczby notifications
  // dlatego tutaj pokazanie błędu formularza zadziała
  // natomiast w przypadku przekierowań zadziała componentDidMount
  componentDidUpdate(prevProps) {
    if (prevProps.notifications.length !== this.props.notifications.length) {
      if (this.props.notifications.length > 0) {
        this.props.hideNotification();
        this.notifRef.current.setNotification({
          type: "error",
          message: FAILURE_MESSAGE
        });
      }
    }
  }

  componentDidMount() {
    const { state } = this.props.location;
    if (state) {
      const { notification, nextPathname } = state;
      if (notification && nextPathname) {
        this.notifRef.current.setNotification({
          type: "error",
          message: TOKEN_FAILURE_MESSAGE
        });
      } else if (notification) {
        const { type, message } = notification;
        this.notifRef.current.setNotification({ type, message });
      }
    }
  }

  render() {
    const { isSubmitting, classes } = this.props;
    const restProps = extractRest(this.props);

    return (
      <AuthLayout snackbar={<AuthNotification ref={this.notifRef} />}>
        <Paper>
          <Typography component="h1" variant="display1">
            Register Viewer
          </Typography>

          <p>Create your Viewer account using your first device credentials.</p>

          <IotaForm
            form={REDUX_FORM_NAME}
            resource="devices"
            toolbar={null}
            onSubmit={this.handleSubmit}
          >
            <div className="form-fields">
              <div className="form-row">
                <div className="w-100">
                  <TextInput
                    source="device_id"
                    label="ID"
                    fullWidth
                    validate={[required()]}
                    {...restProps}
                  />

                  <TextInput
                    source="psc"
                    label="PSC"
                    fullWidth
                    validate={validatePsc}
                    {...restProps}
                  />
                </div>
              </div>
            </div>
          </IotaForm>

          <IotaSaveButton
            variant="contained"
            fullWidth
            onClick={this.handleSave}
            label={"Register viewer"}
            saving={isSubmitting}
            className={classes.viewerButton}
          />

          <p className={classes.pushedTop}>Already registered?</p>

          <Button
            fullWidth
            component={Link}
            to="/login_viewer"
            variant="outlined"
            color="secondary"
          >
            Login as a viewer
          </Button>

          <Button
            fullWidth
            component={Link}
            to="/login"
            variant="flat"
            color="primary"
            className={classes.pushedTop}
          >
            Back to Owner App
          </Button>
        </Paper>
      </AuthLayout>
    );
  }
}

const StyledRegisterViewerPage = withStyles(styles)(RegisterViewerPage);

const mapStateToProps = state => ({
  isSubmitting: isSubmitting(REDUX_FORM_NAME)(state),
  notifications: state.admin.notifications
});

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  submit,
  reset,
  push,
  hideNotification
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledRegisterViewerPage);
