import React from "react";
import moment from "moment";
import HttpClient from "../../../HttpClient";
import Config from "../../../Config";

export const TIME_FORMAT = "YYYY-MM-DD HH:mm:ssZZ";
export const DISPLAY_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
const BUCKETS_IN_SECS = {
  "30_days": 24 * 60 * 60,
  "7_days": (5 * 60 + 40) * 60,
  "24_hours": 60 * 60,
  "4_hours": 8 * 60
};

const NOOP = _ => {};

class ChartDataController extends React.Component {
  _isMounted = false;
  state = {
    bucket: BUCKETS_IN_SECS["4_hours"],
    data: []
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadCurrentRange();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if (JSON.stringify(nextProps) !== JSON.stringify(this.props)) {
      this.loadCurrentRange();
    }
  }

  requestParamsFromRange = () => {
    const { currentRange } = this.props;
    const [timeValue, timeUnit] = currentRange.split("_");
    const time_start = moment()
      .subtract(timeValue, timeUnit)
      .format(TIME_FORMAT);
    const time_end = moment().format(TIME_FORMAT);
    const buckets = BUCKETS_IN_SECS[currentRange];
    return { time_start, time_end, buckets, adjust_time: true };
  };

  requestParamsFromZoom = zoomArea => () => {
    const { bucket, data } = this.state;
    const zoomStartIdx = Math.min(...zoomArea);
    const zoomEndIdx = Math.max(...zoomArea);
    const time_start = moment(data[zoomStartIdx].bucket)
      .subtract(bucket, "s")
      .format(TIME_FORMAT);
    const time_end = data[zoomEndIdx].bucket;
    const buckets = Math.trunc((moment(time_end).unix() - moment(time_start).unix()) / 24);
    return { time_start, time_end, buckets, adjust_time: false };
  };

  loadData = (makeRequestParams, newDataCallback = NOOP) => {
    const requestParams = makeRequestParams();
    HttpClient(`${Config.api.HOST}${this.props.params.data_endpoint}`, {
      method: "POST",
      body: JSON.stringify({ dev_id: this.props.params.device_id, ...requestParams })
    })
      .then(data => {
        this._isMounted &&
          this.setState(
            {
              data: data.json,
              bucket: requestParams.buckets
            },
            newDataCallback(data.json)
          );
      })
      .catch(err => {
        console.log("Error fetching chart data: ", err);
      });
  };

  loadCurrentRange = (shouldRunCallback = true) => {
    const updater = shouldRunCallback ? this.props.onNewData : NOOP;
    this.loadData(this.requestParamsFromRange, updater);
  };

  loadCurrentZoom = zoomArea =>
    this.loadData(this.requestParamsFromZoom(zoomArea), this.props.onNewData);

  render() {
    return this.props.children({
      data: this.state.data,
      bucket: this.state.bucket,
      loadData: this.loadCurrentZoom
    });
  }
}

export default ChartDataController;
