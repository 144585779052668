import React from "react";

export function labelWithSubscript(label, sub) {
  return (
    <>
      {label}
      <sub>{sub}</sub>
    </>
  );
}

export function formattedValue(value, decimals, uom, sub=false) {
    return value
        ?
        (
            <>
                {Number(value).toFixed(decimals || 0)}
                {sub ? <sub>{uom}</sub> : uom}
            </>
        )
        : "—";
}

export default function ValueWithLabel({ className, label, children }) {
  return (
    <div className={`meter-value tc flex-baseline ${className}`}>
      <div className="meter-value-label">{label}</div>
      <div>{children}</div>
    </div>
  );
}
