import React, { Component } from "react";

import { connect } from "react-redux";
import { fetchEnd, fetchStart, showNotification } from "react-admin";
import { refreshView as refreshViewAction } from "ra-core";

import HttpClient from "../../HttpClient";
import Config from "../../Config";

import Button from "@material-ui/core/Button";

import IotaDialog from "../../ra-ui-iota/IotaDialog";
import IotaSaveButton from "../../ra-ui-iota/IotaSaveButton";

class RemoveFromMonitoredDevices extends Component {
  state = {
    showDialog: false
  };

  handleDialogOpen = () => {
    this.setState({ showDialog: true });
  };
  handleDialogClose = () => {
    this.setState({ showDialog: false });
  };

  handleDelete = () => {
    const { record, showNotification, fetchStart, fetchEnd } = this.props;
    const { monitor_id } = record;
    fetchStart();

    HttpClient(`${Config.api.HOST}/monitors?id=in.(${monitor_id})`, {
      method: "DELETE"
    })
      .then(() => {
        this.handleDialogClose();
        this.props.refreshView();
        showNotification("messages.monitor_device_removed");
      })
      .catch(e => {
        showNotification(e.message, "warning");
      })
      .finally(() => {
        fetchEnd();
      });
  };

  render() {
    const { showDialog } = this.state;
    const { dialog } = this.props;

    return (
      <>
        <Button color="primary" size="small" onClick={this.handleDialogOpen}>
          remove from charts
        </Button>

        <IotaDialog
          isOpen={showDialog}
          onClose={this.handleDialogClose}
          dialog={dialog}
          save={
            <IotaSaveButton
              color="primary"
              onClick={this.handleDelete}
              label={dialog.delete}
            />
          }
        >
          <p className="m-0">{dialog.description}</p>
        </IotaDialog>
      </>
    );
  }
}

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
  refreshView: refreshViewAction
};

export default connect(
  null,
  mapDispatchToProps
)(RemoveFromMonitoredDevices);
