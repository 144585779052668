import React from "react";
import PropTypes from "prop-types";

import Checkbox from "@material-ui/core/Checkbox";

import { toggleListItem } from "ra-core";
import { connect } from "react-redux";

import parseResourceSelectedIds from "./parseResourceSelectedIds";

const MonitoredCheckbox = ({
  resourceName,
  resourceId,
  toggleListItem,
  selectedIds
}) => {
  return (
    <Checkbox
      checked={selectedIds.indexOf(resourceId) !== -1}
      value={resourceId}
      color="primary"
      style={{ justifyContent: "initial", width: "32px", marginRight: "4px" }}
      onClick={() => toggleListItem(resourceName, resourceId)}
    />
  );
};

MonitoredCheckbox.propTypes = {
  resourceName: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired
};

const mapStateToProps = ({ admin: { resources } }, { resourceName }) => ({
  selectedIds: parseResourceSelectedIds(resources, resourceName)
});

const mapDispatchToProps = {
  toggleListItem
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonitoredCheckbox);
