import React from "react";
import PropTypes from "prop-types";

import Config from "../../../../Config";
import { connect } from "react-redux";
import { refreshView as refreshViewAction } from "ra-core";
import addDefaultDeviceParameters from "../../addDefaultDeviceParameters";

const TIMEOUT_IN_MS = 10000;

const timeout = value =>
  new Promise((_, reject) => setTimeout(() => reject({ msg: "Timeout" }), value));

const createDevice = data => {
  return new Promise((resolve, reject) =>
    setTimeout(
      () =>
        fetch(`${Config.api.HOST}/devices`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          },
          body: JSON.stringify(addDefaultDeviceParameters(data))
        })
          .then(response =>
            response.json().then(text => ({
              meta: response
            }))
          )
          .then(response => {
            const { meta } = response;
            if (meta.status >= 300) {
              resolve({
                type: "server_error",
                msg: meta.statusText,
                code: meta.status
              });
            } else if (meta.status < 200) {
              resolve({
                type: "error",
                msg: meta.statusText,
                code: meta.status
              });
            } else {
              resolve({
                type: "created",
                msg: meta.statusText,
                code: meta.status
              });
            }
          })
          .catch(error => resolve(error)),
      250
    )
  );
};

export function statusToName(status) {
  switch (status) {
    case "started":
      return "Initializing...";
    case "checking":
      return "Running...";
    case "finished":
      return "Completed";
    case "finishedErrors":
      return "Completed with errors";
    default:
      return "Not started";
  }
}

export function isError({ result: r }) {
  return r.type === "error" || r.type === "server_error";
}

class QueuedDeviceCreator extends React.Component {
  static propTypes = {
    csvRows: PropTypes.array.isRequired,
    children: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.initialState = {
      status: "new",
      current: null,
      remaining: [],
      completed: [],
      totalCount: 0,
      retry: true,
      startFn: this.startProcess
    };
    this.state = Object.assign({}, this.initialState);
  }

  componentDidMount() {
    return this.startProcess();
  }

  startProcess = () => {
    this.setState(Object.assign({}, this.initialState, { status: "started" }));
    const { csvRows } = this.props;
    const totalCount = csvRows.length;
    const current = Object.assign({}, csvRows[0]);
    const remaining = csvRows.slice(1);
    const status = "checking";
    this.setState({ status, current, remaining, totalCount }, () => this.runCreate(current));
  };

  runCreate = deviceToCreate => {
    Promise.race([createDevice(deviceToCreate), timeout(TIMEOUT_IN_MS)])
      .then(result => this.handleCheckResult(deviceToCreate, result, "created"))
      .catch(error => this.handleCheckResult(deviceToCreate, error, "error"));
  };

  handleCheckResult = (deviceToCreate, result, type) => {
    const { remaining: currentRemaining, completed: currentCompleted } = this.state;
    const { refreshView } = this.props;
    let { current, retry } = this.state;
    // retry disabled
    // if (result.code !== 0 && retry) {
    //   // retry once
    //   this.setState({ retry: !retry }, () => this.runCreate(current));
    //   return;
    // }
    const completed = [this.makeCheckResult(deviceToCreate, result, type)].concat(currentCompleted);
    if (currentRemaining.length > 0) {
      retry = true;
      current = Object.assign({}, currentRemaining[0]);
      const remaining = currentRemaining.slice(1);
      this.setState({ remaining, current, completed, retry }, () => this.runCreate(current));
    } else {
      const status = completed.findIndex(isError) > -1 ? "finishedErrors" : "finished";
      refreshView();
      this.setState({ current: null, status, completed });
    }
  };

  makeCheckResult = (checkedItem, rawResult, _) =>
    Object.assign({}, checkedItem, {
      result: Object.assign({}, rawResult)
    });

  render() {
    return this.props.children(this.state);
  }
}

const mapDispatchToProps = {
  refreshView: refreshViewAction
};

export default connect(
  null,
  mapDispatchToProps
)(QueuedDeviceCreator);
