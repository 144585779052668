import React from "react";

import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";

import { showNotification } from "react-admin";

import { connect } from "react-redux";
import { push } from "react-router-redux";

import HttpClient from "../HttpClient";
import Config from "../Config";

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit * 2
  },
  icon: {
    cursor: "pointer"
  },
  hideBadge: {
    display: "none",
    cursor: "pointer"
  },
  badge: {
    width: "auto",
    borderRadius: 4,
    padding: "0px 4px",
    cursor: "pointer"
  }
});

class NotificationsIconWithBadge extends React.Component {
  state = {
    count: 0
  };

  goToNotifications = () => {
    this.props.push("/time_series_alerts");
  };

  fetchCount = () => {
    HttpClient(`${Config.api.HOST}/count_time_series_alerts`, {
      method: "GET"
    })
      .then(data => {
        this.setState({ count: data.json[0].count });
      })
      .catch(error => {
        showNotification(error.message, "error");
      });
  };

  componentDidMount() {
    this.fetchCount();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.loading === 0 &&
      this.props.loading === 1 &&
      prevProps.loading !== this.props.loading
    ) {
      this.fetchCount();
    }
  }

  render() {
    const { classes } = this.props;
    const { count } = this.state;
    const badge = count > 0 ? classes.badge : classes.hideBadge;

    return (
      <Badge
        onClick={this.goToNotifications}
        classes={{ badge }}
        className={classes.margin}
        badgeContent={this.state.count}
        color="error"
      >
        <NotificationsIcon className={classes.icon} />
      </Badge>
    );
  }
}

const mapStateToProps = ({ admin: { loading } }) => {
  return { loading };
};

const StyledNotificationsIconWithBadge = withStyles(styles)(NotificationsIconWithBadge);

export default connect(
  mapStateToProps,
  {
    push,
    showNotification
  }
)(StyledNotificationsIconWithBadge);
