import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Paper, Typography, TextField } from "@material-ui/core";

import HttpClient from "../HttpClient";
import Config from "../Config";
import AuthLayout from "./AuthLayout";
import AuthNotification from "./AuthNotification";

const FAILURE_MESSAGE =
  "This e-mail doesn't exist or reset password email was already sent";
const SUCCESS_MESSAGE = "Reset password email sent";

class PasswordForgottenPage extends Component {
  notifRef = React.createRef();

  state = {
    email: "",
    error: false,
    success: false
  };

  handleChange = ({ target }) => {
    this.setState({ [target.id]: target.value });
  };

  submit = e => {
    e.preventDefault();
    const { email } = this.state;

    HttpClient(Config.api.HOST + "/request_password_change", {
      method: "POST",
      body: JSON.stringify({ email })
    })
      .then(result => {
        this.setState(
          _state => ({ email: '' }),
          () =>
            this.notifRef.current.setNotification({
              type: "success",
              message: SUCCESS_MESSAGE
            })
        );
      })
      .catch(error => {
        this.notifRef.current.setNotification({
          type: "error",
          message: FAILURE_MESSAGE
        });
      });
  };

  render() {
    const { email, error } = this.state;

    return (
      <AuthLayout snackbar={<AuthNotification ref={this.notifRef} />}>
        <Paper>
          <Typography component="h1" variant="display1">
            Forgot password
          </Typography>
          <form onSubmit={this.submit}>
            <TextField
              id="email"
              label="E-mail"
              type="email"
              value={email}
              onChange={this.handleChange}
              margin="normal"
              fullWidth
              required
              autoFocus={true}
              error={error}
            />
            <Button type="submit" fullWidth variant="contained" color="primary">
              Reset password
            </Button>
            <Button
              fullWidth
              variant="text"
              component={Link}
              to="/login"
              style={{color: "black"}}
            >
              Cancel
            </Button>
          </form>
        </Paper>
      </AuthLayout>
    );
  }
}

export default PasswordForgottenPage;
