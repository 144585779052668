import React from "react";

import {
  TextInput,
  SelectInput,
  ReferenceInput,
  RadioButtonGroupInput,
  FormDataConsumer
} from "react-admin";

import { required, choices, number, minValue, maxLength } from "react-admin";
import { extractRest } from "../../../utils/extractRest";
import {
  messageFormats,
  messageFormatsOptions,
  configurableMsgType,
  MessageFormatConfig
} from "../DeviceFormFields";

const DeviceBulkFormFields = props => {
  const restProps = extractRest(props);
  return (
    <div className="form-fields">
      <div className="form-section-title">Unit of measure & Type</div>
      <div className="form-row">
        <div className="w-100 w-45-m">
          <TextInput
            source="uom"
            label="Unit of measure"
            className="m-0"
            fullWidth
            validate={[maxLength(6)]}
            {...restProps}
          />
        </div>
        <div className="w-100 w-45-m">
          <FormDataConsumer {...restProps}>
            {({ formData, ...rest }) =>
              configurableMsgType(formData.message_format) && (
                <ReferenceInput
                  label="Meter Type"
                  source="meter_type"
                  reference="meter_types"
                  className="m-0"
                  fullWidth
                  validate={required()}
                  {...restProps}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              )
            }
          </FormDataConsumer>
        </div>
      </div>

      <div className="form-section-title">Data format</div>
      <div className="w-100">
        <RadioButtonGroupInput
          source="message_format[type]"
          label=""
          className="m-0"
          fullWidth
          validate={choices(messageFormats, "is required")}
          defaultValue={"basic"}
          choices={messageFormatsOptions}
          {...restProps}
        />
      </div>

      <FormDataConsumer {...restProps}>
        {({ formData, ...rest }) => {
          return (
            <MessageFormatConfig msgFormat={formData.message_format} {...rest} {...restProps} />
          );
        }}
      </FormDataConsumer>

      <div className="form-row">
        <div className="w-100 w-45-m">
          <ReferenceInput
            label="Transmit interval"
            source="transmit_interval"
            reference="transmit_intervals"
            className="m-0"
            fullWidth
            validate={required()}
            {...restProps}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </div>
      </div>

      <div className="form-section-title">Value adjustments</div>
      <div className="form-row">
        <div className="w-100 w-45-m">
          <TextInput
            source="multiplier"
            label="Multiplier"
            className="m-0"
            fullWidth
            validate={[number(), minValue(0)]}
            {...restProps}
          />
        </div>
        <div className="w-100 w-45-m">
          <TextInput
            source="shift"
            label="Shift"
            className="m-0"
            fullWidth
            validate={number()}
            {...restProps}
          />
        </div>
      </div>
    </div>
  );
};

export default DeviceBulkFormFields;
