import React from "react";
import { withStyles } from "@material-ui/core";
import MonitorColumn from "./MonitorColumn";
import MonitorSort from "./MonitorSort";
import MonitorSelectAll from "./MonitorSelectAll";

const styles = theme => ({
  monitorsContainer: {
    padding: "16px 16px",
    [theme.breakpoints.up("md")]: {
      padding: "16px 24px"
    }
  },
  columnsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: "32px"
  }
});

class Monitors extends React.Component {
  constructor(props) {
    super(props);
    if ("IntersectionObserver" in window) {
      this.callbacks = new WeakMap();
      this.elemObserver = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach(entry => {
            const elem = entry.target;
            const callback = this.callbacks.get(elem);
            if (entry.isIntersecting && callback) {
              callback();
              this.callbacks.delete(elem);
              observer.unobserve(elem);
            }
          });
        },
        { rootMargin: "0px 0px 0px 0px" }
      );
    }
  }

  observeVisibility = (elem, callback) => {
    if (this.elemObserver) {
      // console.log("Observer set up. Add elem.", elem, callback);
      this.callbacks.set(elem, callback);
      this.elemObserver.observe(elem);
    } else {
      // console.log("No observer. All visible");
      callback();
    }
  };

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.ids !== this.props.ids ||
      JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)
    );
  }
  render() {
    const { ids, data, basePath, currentSort, setSort, classes } = this.props;
    return (
      <div className={classes.monitorsContainer}>
        {ids.length > 0 && (
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <MonitorSort currentSort={currentSort} setSort={setSort} />
            <MonitorSelectAll />
          </div>
        )}
        <div className={classes.columnsContainer}>
          {ids.map(id => (
            <MonitorColumn
              key={id}
              basePath={basePath}
              id={id}
              data={data[id]}
              observeVisibility={this.observeVisibility}
            />
          ))}
        </div>
      </div>
    );
  }
}

Monitors.defaultProps = {
  data: {},
  ids: []
};

export default withStyles(styles)(Monitors);
