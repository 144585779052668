const addDefaultDeviceParameters = values => {
  values.message_format.msg_length = 24;
  if (["temperature", "temphumid", "temphumidco2"].includes(values.message_format.type)) {
    values.message_format.measurement_count = 4;
    values.meter_type = values.message_format.type;
  }
  return values;
};

export default addDefaultDeviceParameters;
