import React from "react";
import RowsPagination from "../../utils/RowsPagination";
import SectionTitle from "../../utils/SectionTitle";
import optionsLabel from "../../utils/optionsLabel";

import DeleteNotifications from "./DeleteNotifications";

// fields
import { List, Datagrid, TextField, DateField } from "react-admin";

export const NotificationsList = props => (
  <>
    <SectionTitle title={props.options.label} />
    <List
      {...props}
      title={optionsLabel(props.options.label)}
      actions={null}
      bulkActionButtons={<DeleteNotifications />}
      pagination={<RowsPagination />}
      perPage={25}
      sort={{ field: "time", order: "DESC" }}
    >
      <Datagrid>
        <TextField source="device_id" label="Device ID" />
        <DateField source="time" locales="pl-PL" showTime />
        <TextField source="rule" label="Rule" />
        <TextField source="triggers" label="Triggers" />
        <TextField source="reference_data" label="Data record" />
      </Datagrid>
    </List>
  </>
);
