import React from "react";
import IotaDialog from "../../../ra-ui-iota/IotaDialog";
import IotaForm from "../../../ra-ui-iota/IotaForm";
import IotaSaveButton from "../../../ra-ui-iota/IotaSaveButton";
import MonitorCreateFormFields from "./MonitorCreateFormFields";

const MonitorCreateFormDialog = ({
  isOpen,
  handleCloseForm,
  isValid,
  reduxFormName,
  handleSubmitForm,
  isSubmitting,
  handleSave
}) => (
  <IotaDialog
    isOpen={isOpen}
    onClose={handleCloseForm}
    dialog={{
      title: "Add monitor device",
      cancel: "Cancel"
    }}
    save={
      <IotaSaveButton
        color="primary"
        disabled={!isValid}
        onClick={handleSave}
        label={"Save"}
        isSubmitting={isSubmitting}
      />
    }
  >
    {isOpen && (
      <IotaForm
        form={reduxFormName}
        resource="devices"
        onSubmit={handleSubmitForm}
        toolbar={null}
      >
        <MonitorCreateFormFields />
      </IotaForm>
    )}
  </IotaDialog>
);

export default MonitorCreateFormDialog;
