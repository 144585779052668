import React from "react";
import { List } from "react-admin";
import RowsPagination from "../../../utils/RowsPagination";
import SectionTitle from "../../../utils/SectionTitle";
import optionsLabel from "../../../utils/optionsLabel";
import Monitors from "./Monitors";
import MonitorFilter from "./MonitorFilter";
import MonitorActions from "./MonitorActions";
import MonitorBulkActions from "../MonitorBulkActions";
import { withStyles } from "@material-ui/core";

export const noMobilePadding = {
  marginLeft: "-8px",
  marginRight: "-8px"
};

const styles = theme => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      ...noMobilePadding
    }
  }
});

const MonitorList = props => {
  if (!props.permissions) return null;
  return (
    <>
      <SectionTitle title={props.options.label} />
      <List
        {...props}
        title={optionsLabel(props.options.label)}
        actions={<MonitorActions />}
        pagination={<RowsPagination />}
        perPage={50}
        sort={{ field: "created_at", order: "DESC" }}
        filters={<MonitorFilter />}
        bulkActionButtons={<MonitorBulkActions />}
      >
        <Monitors />
      </List>
    </>
  );
};

export default withStyles(styles)(MonitorList);
