import React from "react";

class ChartNavController extends React.Component {
  state = {
    activeNav: this.props.initialNav
  };

  updateNav = newNav => () => {
    this.setState({ activeNav: newNav });
  };

  render() {
    return this.props.children({
      activeNav: this.state.activeNav,
      updateNav: this.updateNav
    });
  }
}

export default ChartNavController;
