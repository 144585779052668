import React from "react";

import { RadioButtonGroupInput, required } from "react-admin";
import { extractRest } from "../../../../utils/extractRest";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  flexDirectionOverride: {
    flexDirection: "column !important"
  }
});

const XlsFormFields = props => {
  const restProps = extractRest(props);
  return (
    <div className="form-fields">
      <div className="form-row">
        <div className="w-100">
          <RadioButtonGroupInput
            source="reporting_interval"
            options={{
              // props przekazywany do RadioGroup material-ui przez operator spread
              className: props.classes.flexDirectionOverride
            }}
            choices={[
              { id: "all", name: "xls.range_button.all" },
              { id: "30_days", name: "xls.range_button.30_days" },
              { id: "7_days", name: "xls.range_button.7_days" },
              { id: "24_hours", name: "xls.range_button.24_hours" },
              { id: "4_hours", name: "xls.range_button.4_hours" }
            ]}
            validate={required()}
            {...restProps}
          />
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(XlsFormFields);
