import React from "react";
import moment from "moment";
import { DISPLAY_TIME_FORMAT } from "../../Charts/ChartDataController";

export default function DeviceAndTime({ device_id, aggregate }) {
  return (
    <div className="flex-cent meter-device-id">
      <div className="device-name">{device_id}</div>
      <div className="last-value-time">
        {aggregate.time ? moment(aggregate.time).format(DISPLAY_TIME_FORMAT) : "no data"}
      </div>
    </div>
  );
}
