import React from 'react';
import RowsPagination from '../../utils/RowsPagination';
import SectionTitle from '../../utils/SectionTitle';
import optionsLabel from '../../utils/optionsLabel';
import {List, Datagrid, TextField, DateField, NumberField} from 'react-admin';

export const TimeSeriesDataList = props => (
  <>
    <SectionTitle title={props.options.label} />
    <List
      {...props}
      title={optionsLabel(props.options.label)}
      actions={null}
      bulkActionButtons={false}
      pagination={<RowsPagination />}
      perPage={25}
      sort={{field: 'time', order: 'DESC'}}>
      <Datagrid>
        <TextField source="device_id" />
        <NumberField source="value" />
        <DateField source="time" locales="pl-PL" showTime />
      </Datagrid>
    </List>
  </>
);
