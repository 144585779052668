const validateImportFormat = (values) => {
  const errors = {};

  if (!values.csv) {
    errors.csv = ["cannot be empty"];
  }

  return errors;
};

export default validateImportFormat;
