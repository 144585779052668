import React from 'react';
import { Pagination } from 'react-admin';

const RowsPagination = props => {
  let { total, ...passedProps }= props;
  if (!total) {
    total = 0;
  }
  return <Pagination rowsPerPageOptions={[25, 50, 100]} total={total} {...passedProps} />;
}

export default RowsPagination;
