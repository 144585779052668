import React from "react";
import PropTypes from "prop-types";
import onlyUpdateForKeys from "recompose/onlyUpdateForKeys";
import MuiButton from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import ContentAdd from "@material-ui/icons/Add";
import compose from "recompose/compose";
import classnames from "classnames";
import { translate } from "ra-core";

import { Button } from "ra-ui-materialui";
import { Responsive } from "ra-ui-materialui";

const styles = theme => ({
  floating: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 60,
    left: "auto",
    position: "fixed",
    zIndex: 1000
  },
  floatingLink: {
    color: "inherit"
  }
});

const MonitorCreateButton = ({
  basePath = "",
  className,
  classes = {},
  translate,
  label = "ra.action.create",
  ...rest
}) => (
  <Responsive
    small={
      <MuiButton
        variant="fab"
        color="primary"
        className={classnames(classes.floating, className)}
        aria-label={label && translate(label)}
        {...rest}
      >
        <ContentAdd />
      </MuiButton>
    }
    medium={
      <Button className={className} label={label && translate(label)} {...rest}>
        <ContentAdd />
      </Button>
    }
  />
);

MonitorCreateButton.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  size: PropTypes.string,
  translate: PropTypes.func.isRequired
};

const enhance = compose(
  translate,
  onlyUpdateForKeys(["basePath", "label"]),
  withStyles(styles)
);

export default enhance(MonitorCreateButton);
