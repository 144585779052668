import decodeJwt from 'jwt-decode';

export const sessionFromToken = (token) => {
  const decodedToken = decodeJwt(token);
  localStorage.setItem('token', token);
  localStorage.setItem('role', decodedToken.role);
  return { token: token, role: decodedToken.role }
}

export default sessionFromToken;
