import React  from "react";
import moment from "moment";
import { DISPLAY_TIME_FORMAT } from "../ChartDataController";
import TemphumidTemperatureChart from "./TemphumidTemperatureChart";
import TemphumidHumidityChart from "./TemphumidHumidityChart";
import TemphumidCo2Chart from "./TemphumidCo2Chart";

export default class Temphumidco2ChartContainer extends React.Component {
  constructor(props) {
    super(props);

    const { loadData, params, bucket } = props;

    this.state = {
      slide: 0,
      loadData,
      bucket,
      params,
      chartCount: 3
    };

    this.updateState(props);
  }

  updateState(props) {
    const { data, loadData, params, bucket } = props;
    const [ruleT, ruleH, ruleCo2] = params.alert_rules || [];
    const { lower_bound: lbT, upper_bound: ubT } = ruleT || {};
    const { lower_bound: lbH, upper_bound: ubH } = ruleH || {};
    const { lower_bound: lbCo2, upper_bound: ubCo2 } = ruleCo2 || {};

    this.setState({
      loadData,
      bucket,
      params,
      chartData: data.map(d => {
        return {
          bucket: d.bucket,
          time: moment(d.time).format(DISPLAY_TIME_FORMAT),
          temperature: d.temperature,
          temperaturemom: d.temperaturemom !== d.temperature ? d.temperaturemom : null,
          humidity: d.humidity,
          humiditymom: d.humiditymom !== d.humidity ? d.humiditymom : null,
          co2: d.co2,
          co2mom: d.co2mom !== d.co2 ? d.co2mom : null,
          "Lower bound T": lbT,
          "Upper bound T": ubT,
          "Lower bound H": lbH,
          "Upper bound H": ubH,
          "Lower bound CO2": lbCo2,
          "Upper bound CO2": ubCo2,
        };
      })
    })
  }

  componentWillReceiveProps(nextProps) {
    this.updateState(nextProps);
  }

  slideUp() {
    this.setState({ slide: this.state.slide - 1 });
  }

  slideDown() {
    this.setState({ slide: this.state.slide + 1 });
  }

  render() {
    return (
      <div style={{width: "99%"}}>
        <div className="chart-slider">
          <nav className="chart-slider__nav">
            <button className="chart-slider__nav-btn chart-slider__nav-btn--up" disabled={this.state.slide <= 0} type="button" onClick={() => this.slideUp()}/>
            <button className="chart-slider__nav-btn chart-slider__nav-btn--down" disabled={this.state.slide >= this.state.chartCount - 2} type="button" onClick={() => this.slideDown()}/>
          </nav>
          <div className="chart-slider__content" style={{top: this.state.slide * -205}}>
            <TemphumidTemperatureChart
              height={205}
              data={this.state.chartData}
              loadData={this.state.loadData}
              canZoomIn={this.state.bucket >= this.state.params.measure_interval}
            />
            <TemphumidHumidityChart
              height={205}
              data={this.state.chartData}
              loadData={this.state.loadData}
              canZoomIn={this.state.bucket >= this.state.params.measure_interval}
            />
            <TemphumidCo2Chart
              height={205}
              data={this.state.chartData}
              loadData={this.state.loadData}
              canZoomIn={this.state.bucket >= this.state.params.measure_interval}
            />
          </div>
        </div>
      </div>
    );
  }
}
