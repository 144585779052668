const whitelistFormatParameters = (record) => {
  const { message_format: { type }} = record;
  switch (type) {
    case 'basic':
      delete record.message_format.increment_bytes;
      delete record.message_format.increment_count;
      break;

    case 'incremental':
      delete record.message_format.value_count;
      break;

    default:
  }
  return record;
}

export default whitelistFormatParameters;
