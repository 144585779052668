import React from "react";
import { Redirect } from "react-router";

class RedirectWhenLogged extends React.Component {
  state = {
    triggerRedirect: false
  };

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.setState({ triggerRedirect: true });
    }
  }

  render() {
    if (this.state.triggerRedirect) {
      return <Redirect to="/monitors" />;
    } else {
      return null;
    }
  }
}

export default RedirectWhenLogged;
