import React from "react";

import {
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ComposedChart,
  ResponsiveContainer,
  ReferenceArea,
  Label
} from "recharts";

import { XAxisTick, YAxisTick } from "../axisTicks";
import ChartZoomController from "../ChartZoomController";
import { tooltipFormatter } from "../tooltipFormatter";

class ValueLineChart extends React.Component {
  render() {
    const { range = "4_hours", data, height, canZoomIn, loadData } = this.props;
    return (
      <ChartZoomController data={data} loadData={loadData} canZoomIn={canZoomIn}>
        {({ zoomAxis, ...mouseEventsHandlers }) => (
          <ResponsiveContainer height={height}>
            <ComposedChart
              data={data}
              margin={{ top: 20, right: 0, left: 20, bottom: 0 }}
              style={{ fontSize: "0.7rem" }}
              {...mouseEventsHandlers}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                padding={{ left: 20, right: 20 }}
                allowDataOverflow={true}
                dataKey="time"
                domain={["dataMin", "dataMax"]}
                type="category"
                tick={<XAxisTick range={range} />}
              />
              <YAxis
                padding={{ top: 10, bottom: 10 }}
                allowDataOverflow={true}
                domain={["dataMin-1", "dataMax+1"]}
                type="number"
                yAxisId="1"
                tick={<YAxisTick format={"0,0.00"} textAnchor="end" />}
              >
                <Label value="Value" offset={0} position="top" />
              </YAxis>
              <Tooltip formatter={tooltipFormatter} />
              <Line
                yAxisId="1"
                type="linear"
                dataKey="Value"
                stroke="#3DB7DA"
                strokeWidth="2"
                animationDuration={200}
              />
              {zoomAxis[0] && zoomAxis[1] ? (
                <ReferenceArea yAxisId="1" x1={zoomAxis[0]} x2={zoomAxis[1]} strokeOpacity={0.3} />
              ) : null}
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </ChartZoomController>
    );
  }
}

export default ValueLineChart;
