import React from "react";
import DeviceAndTime from "./DeviceAndTime";
import ValueWithLabel, { labelWithSubscript, formattedValue } from "./ValueWithLabel";

export default function TemphumidMeter(props) {
  const { aggregate: { values = [] } } = props;
  const [tave, tmom, have, hmom] = values;
  return (
    <div className="absolute-container">
      <DeviceAndTime {...props} />
      <div className="temphumid-data">
        <div className="values-column">
          <ValueWithLabel label={labelWithSubscript("T", "ave")} className="temphumid-t">
            <div>{formattedValue(tave, 1, "°")}</div>
          </ValueWithLabel>
          <ValueWithLabel label={labelWithSubscript("T", "end")} className="temphumid-t">
            <div>{formattedValue(tmom, 1, "°")}</div>
          </ValueWithLabel>
        </div>
        <div className="values-column">
          <ValueWithLabel label={labelWithSubscript("H", "ave")} className="temphumid-h">
            <div>{formattedValue(have, 0, "%")}</div>
          </ValueWithLabel>
          <ValueWithLabel label={labelWithSubscript("H", "end")} className="temphumid-h">
            <div>{formattedValue(hmom, 0, "%")}</div>
          </ValueWithLabel>
        </div>
      </div>
    </div>
  );
}
