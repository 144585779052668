import React from "react";

import { Grid } from "@material-ui/core";
import GuestThemeProvider from "./GuestThemeProvider";
import RedirectWhenLogged from "./RedirectWhenLogged";
import logoWhite from "../img/logo-white.svg";

function WelcomeHeadline(props) {
  return (
    <div
      style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}
    >
      <div style={{ flex: "none", color: "white", marginRight: "1rem", fontSize:"1.8rem", lineHeight: 1 }}>
        {props.children}
      </div>
      <div style={{minWidth: "100px"}}>
        <img src={logoWhite} width="180" alt="Company logo" />
      </div>
    </div>
  );
}

export default function AuthLayout({ children, snackbar = null }) {
  return (
    <GuestThemeProvider>
      <RedirectWhenLogged />
      <Grid container direction="column" justify="center" alignItems="center">
        <WelcomeHeadline>Welcome to</WelcomeHeadline>
        {children}
      </Grid>
      {snackbar}
    </GuestThemeProvider>
  );
}
