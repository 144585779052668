import React from "react";
import RowsPagination from "../../utils/RowsPagination";
import SectionTitle from "../../utils/SectionTitle";
import optionsLabel from "../../utils/optionsLabel";
import Actions from "./Actions";
import Filter from "./Filter";

// fields
import { List, Datagrid, TextField, DateField, EditButton } from "react-admin";
import AddRemoveMonitors from "./AddRemoveMonitors";
import BulkEdit from "./BulkEdit";
import DeleteRecord from "../monitors/DeleteRecord";
import { isAllowed } from "../../AuthProvider";

export const DevicesList = props => {
  if (!props.permissions) return null;
  const canDeleteDevices = isAllowed(props.permissions)("delete_devices");
  const editButtonStyle = canDeleteDevices
    ? { style: { minWidth: "32px", width: "32px", padding: "5px" } }
    : null;
  return (
    <>
      <SectionTitle title={props.options.label} />
      <List
        {...props}
        title={optionsLabel(props.options.label)}
        actions={<Actions />}
        bulkActionButtons={<BulkEdit actionLabel="Edit settings" />}
        pagination={<RowsPagination />}
        perPage={25}
        sort={{ field: "created_at", order: "DESC" }}
        filters={<Filter />}
      >
        <Datagrid>
          <TextField source="id" label="ID" />
          <TextField source="name" label="Device Name" />
          <TextField source="meter_type" label="Meter Type" />
          <DateField source="created_at" locales="pl-PL" showTime label="Connected" />
          <TextField source="message_format[type]" label="Format" sortBy="message_format->type" />
          <TextField source="email" label="Owner" />
          <EditButton
            basepath="/devices"
            label={canDeleteDevices ? "" : "edit"}
            {...editButtonStyle}
          />
          {canDeleteDevices ? (
            <DeleteRecord
              resource="devices"
              title="Delete device"
              notificationKey="messages.device_deleted"
            >
              <p className="m-0">
                <strong>WARNING:</strong> This action cannot be reversed!
              </p>
              <p>All connected data (charts, alerts, time series data) will also be deleted.</p>
              <p>Do you want to delete this device?</p>
            </DeleteRecord>
          ) : null}
          <AddRemoveMonitors />
        </Datagrid>
      </List>
    </>
  );
};
