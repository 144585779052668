import React from "react";
import { connect } from "react-redux";
import { change, submit, isSubmitting, isValid, reset } from "redux-form";
import {
  fetchEnd,
  fetchStart,
  showNotification,
  crudGetOne,
  Button,
  REDUX_FORM_NAME
} from "react-admin";

import HttpClient from "../../../HttpClient";
import Config from "../../../Config";

import OpenIcon from "@material-ui/icons/Edit";
import EditFormDialog from "./MonitorEditFormDialog";

import prepareAlertRules from "../MonitorCreate/prepareAlertRules";

class MonitorEdit extends React.Component {
  state = {
    error: false,
    showFormDialog: false
  };

  formId = ({ id }) => `update-monitor-${id}`;

  handleOpenForm = () => {
    this.setState({ showFormDialog: true });
  };

  handleCancelForm = () => {
    this.props.reset(REDUX_FORM_NAME);
    this.handleCloseForm();
  };

  handleCloseForm = () => {
    this.setState({ showFormDialog: false });
  };

  handleSave = () => {
    const { submit, record } = this.props;
    submit(this.formId(record));
  };

  handleSubmitForm = values => {
    const { crudGetOne, fetchStart, fetchEnd, showNotification, record } = this.props;

    fetchStart();
    HttpClient(`${Config.api.HOST}/monitors?id=eq.${record.id}`, {
      method: "PATCH",
      body: JSON.stringify(prepareAlertRules(values))
    })
      .then(() => {
        crudGetOne("monitors", record.id);
        this.setState({ showFormDialog: false });
      })
      .catch(error => {
        showNotification(error.message, "error");
      })
      .finally(() => {
        fetchEnd();
      });
  };

  render() {
    const { showFormDialog } = this.state;
    const { isSubmitting, isValid, record } = this.props;

    const values = {
      name: record.name,
      valchange_alert: record.valchange_alert,
      message_type: record.message_type,
      alert_rule_0: record.alert_rules ? record.alert_rules[0] : null,
      alert_rule_1: record.alert_rules ? record.alert_rules[1] : null,
      alert_rule_2: record.alert_rules ? record.alert_rules[2] : null,
    };

    return (
      <>
        <Button
          onClick={this.handleOpenForm}
          style={{ minWidth: "32px", width: "32px", padding: "5px" }}
        >
          <OpenIcon />
        </Button>

        {showFormDialog && (
          <EditFormDialog
            handleCloseForm={this.handleCancelForm}
            handleSave={this.handleSave}
            handleSubmitForm={this.handleSubmitForm}
            isOpen={showFormDialog}
            isSubmitting={isSubmitting}
            isValid={isValid}
            reduxFormName={this.formId(record)}
            record={values}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting(REDUX_FORM_NAME)(state),
  isValid: isValid(REDUX_FORM_NAME)(state)
});

const mapDispatchToProps = {
  change,
  crudGetOne,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  reset
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonitorEdit);
