import React, {Component} from 'react';
import {connect} from 'react-redux';
import {crudUpdate} from 'react-admin';
import whitelistFormatParameters from './whitelistFormatParameters';
import addDefaultDeviceParameters from './addDefaultDeviceParameters';
import sanitizeDeviceParameters from './sanitizeDeviceParameters';

// dialog resources
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IotaDialog from '../../ra-ui-iota/IotaDialog';
import IotaSaveButton from '../../ra-ui-iota/IotaSaveButton';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  marginRight: {
    marginRight: theme.spacing.unit,
  },
});

const saveDevice = (values, previousValues, basePath, redirectTo) => {
  let cleanValues = whitelistFormatParameters(values);
  cleanValues = addDefaultDeviceParameters(cleanValues);
  cleanValues = sanitizeDeviceParameters(cleanValues);

  return crudUpdate(
    'devices',
    values.id,
    cleanValues,
    previousValues,
    basePath,
    redirectTo,
  );
}

class SaveWithWarningView extends Component {
  state = {
    dialogOpen: false,
  };

  handleSave = () => {
    const {basePath, handleSubmit, redirect, saveDevice} = this.props;

    return handleSubmit((values, saga, details) => {
      saveDevice(values, details.record, basePath, redirect);
    });
  };

  handleDialogOpen = () => {
    this.setState({dialogOpen: true});
  };

  handleDialogClose = () => {
    this.setState({dialogOpen: false});
  };

  render() {
    const {
      handleSubmitWithRedirect,
      saveWithWarning,
      dialog,
      classes,
      invalid,
      ...props
    } = this.props;
    return (
      <>
        <Button
          onClick={this.handleDialogOpen}
          color="primary"
          disabled={invalid}
          variant="contained">
          {dialog.open}
        </Button>

        <IotaDialog
          dialog={dialog}
          isOpen={this.state.dialogOpen}
          onClose={this.handleDialogClose}
          save={
            <IotaSaveButton
              handleSubmitWithRedirect={this.handleSave}
              label={dialog.save}
              {...props}
            />
          }>
          {dialog.description}
        </IotaDialog>
      </>
    );
  }
}

const SaveWithWarning = connect(
  undefined,
  {saveDevice},
)(SaveWithWarningView);

export default withStyles(styles)(SaveWithWarning);
