import React from "react";
import { aggregatorFactory } from "./aggregators";

class MeterValuesAggregator extends React.Component {
  constructor(props) {
    super(props);
    const { message_type } = props;
    const { time, values } = props.init;
    this.state = {
      aggregate: {
        time,
        values: ["basic", "incremental"].includes(message_type) ? values : []
      }
    };
    this.aggregator = aggregatorFactory(message_type);
  }

  aggregateValues = (newRecords = []) => {
    const aggregate = this.aggregator(newRecords, this.state.aggregate);
    if (aggregate) this.setState({ aggregate });
  };

  render() {
    return this.props.children({
      aggregate: this.state.aggregate,
      aggregateValues: this.aggregateValues
    });
  }
}

export default MeterValuesAggregator;
