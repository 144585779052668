import React from "react";
import { connect } from "react-redux";
import { change, submit, reset } from "redux-form";
import { fetchEnd, fetchStart, showNotification, Button } from "react-admin";
import { refreshView as refreshViewAction } from "ra-core";

import HttpClient from "../../../HttpClient";
import Config from "../../../Config";

import OpenIcon from "@material-ui/icons/Delete";
import DeleteDialog from "./DeleteDialog";

class DeleteRecord extends React.Component {
  state = {
    error: false,
    showDeleteDialog: false
  };

  handleOpen = () => {
    this.setState({ showDeleteDialog: true });
  };

  handleCancel = () => {
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ showDeleteDialog: false });
  };

  handleDelete = _values => {
    const {
      fetchStart,
      fetchEnd,
      showNotification,
      record,
      resource,
      notificationKey
    } = this.props;

    fetchStart();

    HttpClient(`${Config.api.HOST}/${resource}?id=eq.${record.id}`, {
      method: "DELETE"
    })
      .then(() => {
        showNotification(notificationKey);
        this.setState({ showDeleteDialog: false });
        setTimeout(() => this.props.refreshView(), 0); // refresh in next tick so component can unmount
      })
      .catch(error => {
        showNotification(error.message, "error");
      })
      .finally(() => {
        fetchEnd();
      });
  };

  render() {
    const { showDeleteDialog } = this.state;

    return (
      <>
        <Button
          onClick={this.handleOpen}
          style={{ minWidth: "32px", width: "32px", padding: "5px" }}
        >
          <OpenIcon />
        </Button>

        {showDeleteDialog && (
          <DeleteDialog
            handleClose={this.handleClose}
            handleDelete={this.handleDelete}
            isOpen={showDeleteDialog}
            title={this.props.title}
          >
            {this.props.children}
          </DeleteDialog>
        )}
      </>
    );
  }
}

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  reset,
  refreshView: refreshViewAction
};

export default connect(
  null,
  mapDispatchToProps
)(DeleteRecord);
