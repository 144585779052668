import React from "react";

import { TextInput, required } from "react-admin";
import { extractRest } from "../../../utils/extractRest";
import { validatePsc } from "../../../validations/validatePsc";

import FormHelperText from "@material-ui/core/FormHelperText";

const MonitoredDeviceFormFields = props => {
  const restProps = extractRest(props);
  return (
    <div className="form-fields">
      <div className="form-row">
        <div className="w-100">
          <TextInput
            className="m-0"
            source="name"
            label="Create device name"
            fullWidth
            validate={[required()]}
            {...restProps}
          />
          <FormHelperText>
            Input a name for a new device, you can change it later
          </FormHelperText>

          <TextInput
            source="device_id"
            label="ID"
            fullWidth
            validate={[required()]}
            {...restProps}
          />
          <FormHelperText>
            Input ID as printed on the device you want to connect
          </FormHelperText>

          <TextInput
            source="psc"
            label="PSC"
            fullWidth
            validate={validatePsc}
            {...restProps}
          />
          <FormHelperText>
            Input PSC as received from your device vendor
          </FormHelperText>
        </div>
      </div>
    </div>
  );
};

export default MonitoredDeviceFormFields;
