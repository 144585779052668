import React, { Component } from "react";
import {
  Button,
  Paper,
  TextField,
  Typography,
  InputAdornment,
  IconButton
} from "@material-ui/core";

import { Visibility, VisibilityOff } from "@material-ui/icons";
import { hideNotification, userLogin } from "react-admin";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import AuthNotification from "./AuthNotification";

import { withStyles } from "@material-ui/core/styles";
import { themeExtension } from "../themeExtension";

const FAILURE_MESSAGE = "Wrong e-mail or password";
const TOKEN_FAILURE_MESSAGE = "Access expired, please sign in again";

const styles = theme => ({
  viewerParagraph: {
    marginTop: theme.spacing.unit * 5,
    textAlign: "center",
    color: themeExtension.palette.tertiary.main
  },
  viewerButton: themeExtension.tertiaryButton
});

class LoginPage extends Component {
  notifRef = React.createRef();

  state = {
    username: "",
    password: "",
    showPassword: false,
    formSubmitted: false
  };

  handleChange = ({ target }) => {
    this.setState({ [target.id]: target.value });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  submit = e => {
    e.preventDefault();
    const { username, password } = this.state;
    this.setState({ formSubmitted: true });
    this.props.userLogin({ username, password });
  };

  // redirect 401 nie powoduje zwiększenia liczby notifications
  // dlatego tutaj pokazanie błędu formularza zadziała
  // natomiast w przypadku przekierowań zadziała componentDidMount
  componentDidUpdate(prevProps) {
    if (prevProps.notifications.length !== this.props.notifications.length) {
      if (this.props.notifications.length > 0) {
        this.props.hideNotification();
        this.notifRef.current.setNotification({
          type: "error",
          message: FAILURE_MESSAGE
        });
      }
    }
  }

  componentDidMount() {
    const { state } = this.props.location;

    if (state) {
      const { notification, nextPathname } = state;
      if (notification && nextPathname) {
        this.notifRef.current.setNotification({
          type: "error",
          message: TOKEN_FAILURE_MESSAGE
        });
      } else if (notification) {
        const { type, message } = notification;
        this.notifRef.current.setNotification({ type, message });
      }
    }

    localStorage.setItem('lastLoginPage', '/login');
  }

  render() {
    const { email, password, showPassword } = this.state;
    const { classes } = this.props;

    return (
      <AuthLayout snackbar={<AuthNotification ref={this.notifRef} />}>
        <Paper>
          <Typography component="h1" variant="display1">
            Sign in
          </Typography>
          <form onSubmit={this.submit}>
            <TextField
              id="username"
              label="E-mail"
              type="email"
              value={email}
              onChange={this.handleChange}
              margin="normal"
              fullWidth
              required
              autoFocus={true}
            />
            <TextField
              id="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={this.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment variant="filled" position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              margin="normal"
              fullWidth
              required
            />
            <Button
              variant="text"
              component={Link}
              to="/password_forgotten"
              style={{
                marginTop: 10,
                textTransform: "initial",
                marginLeft: "-16px"
              }}
              color="primary"
            >
              Forgot password
            </Button>
            <Button type="submit" fullWidth variant="contained" color="primary">
              Sign in
            </Button>
            <Button
              fullWidth
              component={Link}
              to="/sign_up"
              variant="outlined"
              color="primary"
            >
              Register
            </Button>

            <p className={classes.viewerParagraph}>Are you a viewer?</p>

            <Button
              fullWidth
              component={Link}
              to="/register_viewer"
              className={classes.viewerButton}
            >
              Go to a viewer app
            </Button>
          </form>
        </Paper>
      </AuthLayout>
    );
  }
}

const StyledLoginPage = withStyles(styles)(LoginPage);

const mapStateToProps = ({ admin }) => {
  return { notifications: admin.notifications };
};

export default connect(
  mapStateToProps,
  { userLogin, hideNotification }
)(StyledLoginPage);
