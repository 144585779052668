import React from "react";
import { Typography } from "@material-ui/core";
import optionsLabel from "./optionsLabel";

const SectionTitle = props => (
  <Typography variant="headline" component="h1" gutterBottom={true}>
    {optionsLabel(props.title)}
  </Typography>
);

export default SectionTitle;
