import React from "react";

import { setListSelectedIds } from "ra-core";
import { connect } from "react-redux";

import { Button } from "@material-ui/core";

const MonitorSelectAll = ({ deviceIds, selectedIds, setListSelectedIds }) => {
  const anySelected = selectedIds.length > 0;
  return (
    <Button
      fullWidth={false}
      style={{ marginLeft: "auto", minWidth: "100px" }}
      onClick={() =>
        setListSelectedIds("monitors", anySelected ? [] : deviceIds)
      }
      size="small"
      variant="text"
      color="primary"
    >
      {anySelected ? "Deselect all" : "Select all"}
    </Button>
  );
};

const mapStateToProps = ({ admin }) => {
  const { list, data } = admin.resources.monitors;
  const { ids, selectedIds } = list;
  const deviceIds = ids.map(id => data[id].device_id);
  return { deviceIds, selectedIds };
};

const mapDispatchToProps = {
  setListSelectedIds
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonitorSelectAll);
