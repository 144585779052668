import React from "react";
import moment from "moment";
import { DISPLAY_TIME_FORMAT } from "../ChartDataController";
import ValueLineChart from "./ValueLineChart";
import ValueChangeRateChart from "./ValueChangeRateChart";

export default function BasicIncrementalChartContainer({ data, loadData, params, bucket }) {
  const [rule = {}] = params.alert_rules || [];
  const { lower_bound, upper_bound } = rule;

  const chartData = data.map(d => {
    return {
      bucket: d.bucket,
      time: moment(d.time).format(DISPLAY_TIME_FORMAT),
      Value: d.value,
      "Lower bound": lower_bound,
      "Upper bound": upper_bound,
      Rate: d.rate
    };
  });
  return (
    <div style={{ width: "99%" }}>
      <ValueLineChart
        height={260}
        data={chartData}
        loadData={loadData}
        canZoomIn={bucket >= params.measure_interval}
      />
      <ValueChangeRateChart
        height={160}
        data={chartData}
        loadData={loadData}
        canZoomIn={bucket >= params.measure_interval}
        message_type={params.message_type}
      />
    </div>
  );
}
