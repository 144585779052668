import React from "react";

// fields
import {
  Toolbar,
  CreateView,
  CreateController,
  CardContentInner
} from "react-admin";
import { withStyles } from "@material-ui/core";

// validations
import validateDeviceFormat from "./ValidateDeviceFormat";
import CreateWithCleanedParams from "./CreateWithCleanedParams";
import CancelButton from "./CancelButton";
import IotaForm from "../../ra-ui-iota/IotaForm";
import SectionTitle from "../../utils/SectionTitle";
import DeviceFormFields from "./DeviceFormFields";

export const toolbarStyles = {
  mobileToolbar: {
    padding: 0
  }
};

const DeviceCreateToolbar = props => {
  return (
    <CardContentInner>
      <Toolbar {...props}>
        <CreateWithCleanedParams
          redirect="list"
          submitOnEnter={false}
          variant="raised"
        />
        <CancelButton target="/devices" />
      </Toolbar>
    </CardContentInner>
  );
};

const StyledToolbar = withStyles(toolbarStyles)(DeviceCreateToolbar)

const deviceDefaultValue = { message_format: { type: "basic" } };

export const DeviceCreate = props => (
  <>
    <SectionTitle title="Register new device" />
    <CreateController {...props}>
      {cp => (
        <CreateView title="Device creation" {...props} {...cp}>
          <IotaForm
            toolbar={<StyledToolbar />}
            defaultValue={deviceDefaultValue}
            validate={validateDeviceFormat}
          >
            <CardContentInner>
              <DeviceFormFields forCreate {...cp} />
            </CardContentInner>
          </IotaForm>
        </CreateView>
      )}
    </CreateController>
  </>
);
