import React from "react";
import { connect } from "react-redux";
import { change, submit, isSubmitting, isValid, reset } from "redux-form";
import { fetchEnd, fetchStart, showNotification, REDUX_FORM_NAME } from "react-admin";
import Button from "@material-ui/core/Button";
import OpenIcon from "@material-ui/icons/Edit";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { refreshView as refreshViewAction } from "ra-core";

import HttpClient from "../../../HttpClient";
import Config from "../../../Config";
import BulkFormDialog from "./BulkFormDialog";
import BulkConfirmDialog from "./BulkConfirmDialog";
import { bulkIconStyles } from "../../monitors/MonitorBulkActions/MonitorBulkEdit";
import addDefaultDeviceParameters from "../addDefaultDeviceParameters";

class BulkEdit extends React.Component {
  state = {
    showFormDialog: false,
    showConfirmation: false
  };

  handleShowForm = () => {
    this.setState({ showFormDialog: true });
  };

  handleCloseForm = () => {
    this.props.reset(REDUX_FORM_NAME);
    this.setState({ showFormDialog: false });
  };

  handleConfirm = () => {
    const { submit } = this.props;
    submit(REDUX_FORM_NAME);
  };

  handleShowConfirmation = () => {
    this.setState({ showConfirmation: true });
  };

  handleCloseConfirmation = () => {
    this.setState({ showConfirmation: false });
  };

  handleSubmitForm = values => {
    const { fetchStart, fetchEnd, showNotification, selectedIds } = this.props;

    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();
    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    HttpClient(`${Config.api.HOST}/rpc/bulk_update_devices`, {
      method: "POST",
      body: JSON.stringify({ ids: selectedIds, new_values: addDefaultDeviceParameters(values) })
    })
      .then(() => {
        this.props.reset(REDUX_FORM_NAME);
        this.setState({ showFormDialog: false, showConfirmation: false });
        this.props.refreshView();
      })
      .catch(error => {
        this.setState({ showConfirmation: false });
        showNotification(error.message, "error");
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  render() {
    const { showFormDialog, showConfirmation } = this.state;
    const { isSubmitting, isValid, selectedIds, actionLabel, classes } = this.props;

    return (
      <>
        <Button color="primary" onClick={this.handleShowForm}>
          <OpenIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
          {actionLabel}
        </Button>

        {showFormDialog && (
          <BulkFormDialog
            isOpen={showFormDialog}
            handleCloseForm={this.handleCloseForm}
            isValid={isValid}
            handleShowConfirmation={this.handleShowConfirmation}
            selectedIds={selectedIds}
            reduxFormName={REDUX_FORM_NAME}
            handleSubmitForm={this.handleSubmitForm}
          />
        )}

        {showConfirmation && (
          <BulkConfirmDialog
            isOpen={showConfirmation}
            handleCloseConfirmation={this.handleCloseConfirmation}
            handleConfirm={this.handleConfirm}
            isValid={isValid}
            isSubmitting={isSubmitting}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting(REDUX_FORM_NAME)(state),
  isValid: isValid(REDUX_FORM_NAME)(state)
});

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  reset,
  refreshView: refreshViewAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(bulkIconStyles)(BulkEdit));
