const sanitizeDeviceParameters = (values) => {
  if (values.shift === "") {
    values.shift = null;
  }

  if (values.multiplier === "") {
    values.multiplier = null;
  }

  return values;
}

export default sanitizeDeviceParameters;
