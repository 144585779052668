import React from "react";
import { connect } from "react-redux";
import { change, submit, isSubmitting, isValid, reset } from "redux-form";
import {
  fetchEnd,
  fetchStart,
  showNotification,
  REDUX_FORM_NAME
} from "react-admin";

import HttpClient from "../../../HttpClient";
import Config from "../../../Config";

import { refreshView as refreshViewAction } from "ra-core";

import MonitorCreateFormDialog from "./MonitorCreateFormDialog";
import MonitorCreateButton from "./MonitorCreateButton";

class MonitorCreate extends React.Component {
  state = {
    showFormDialog: false
  };

  handleShowForm = () => {
    this.setState({ showFormDialog: true });
  };

  handleCloseForm = () => {
    this.props.reset(REDUX_FORM_NAME);
    this.setState({ showFormDialog: false });
  };

  handleSave = () => {
    const { submit } = this.props;
    submit(REDUX_FORM_NAME);
  };

  handleSubmitForm = values => {
    const { fetchStart, fetchEnd, showNotification } = this.props;
    const { device_id, name, psc } = values;

    fetchStart();

    HttpClient(`${Config.api.HOST}/monitors`, {
      method: "POST",
      body: JSON.stringify({ device_id, name, psc })
    })
      .then(() => {
        this.props.reset(REDUX_FORM_NAME);
        this.setState({ showFormDialog: false });
        this.props.refreshView();
        showNotification("messages.device_added", "info");
      })
      .catch(error => {
        const { message } = error;
        if (message === "device_not_found") {
          showNotification("messages.device_not_found", "error");
        } else if (
          message.match("monitor_viewer_id_device_id_key") ||
          message.match("monitor_user_id_device_id_key")
        ) {
          showNotification("messages.device_already_added", "error");
        } else {
          showNotification(error.message, "error");
        }
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  render() {
    const { showFormDialog } = this.state;
    const { isSubmitting, isValid, selectedIds, actionLabel } = this.props;

    return (
      <>
        <MonitorCreateButton
          fullWidth={false}
          onClick={this.handleShowForm}
          color="primary"
          variant="extendedFab"
          style={{
            minHeight: "initial",
            height: "32px",
            padding: "0 16px"
          }}
          label={actionLabel}
        />

        {showFormDialog && (
          <MonitorCreateFormDialog
            isOpen={showFormDialog}
            handleCloseForm={this.handleCloseForm}
            isValid={isValid}
            handleShowConfirmation={this.handleShowConfirmation}
            selectedIds={selectedIds}
            reduxFormName={REDUX_FORM_NAME}
            handleSubmitForm={this.handleSubmitForm}
            isSubmitting={isSubmitting}
            handleSave={this.handleSave}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting(REDUX_FORM_NAME)(state),
  isValid: isValid(REDUX_FORM_NAME)(state)
});

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  reset,
  refreshView: refreshViewAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonitorCreate);
