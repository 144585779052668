import React from "react";
import Papa from "papaparse";

import { connect } from "react-redux";
import { change, submit, isSubmitting, isValid, reset } from "redux-form";
import { fetchEnd, fetchStart, showNotification } from "react-admin";

import CsvImportFormDialog from "./CsvImportFormDialog";
import CsvImportProgressDialog from "./CsvImportProgressDialog";

import { withStyles } from "@material-ui/core/styles";
import ImportIcon from "@material-ui/icons/Publish";
import Button from "@material-ui/core/Button";

import mapDeviceParameters from "./mapDeviceParameters";
import missingDeviceCsvColumns from "./missingDeviceCsvColumns";

const FORM_NAME = `device_csv_import`;

const styles = theme => ({
  button: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  marginRight: {
    marginRight: theme.spacing.unit
  },
  smallIcon: {
    fontSize: 20
  }
});

class DeviceCsvImport extends React.Component {
  state = {
    showFormDialog: false,
    showProgressDialog: false,
    csvRows: [],
    csvFileName: "",
    csvError: null
  };

  handleShowForm = () => {
    this.setState({ showFormDialog: true, showProgressDialog: false });
  };

  handleCloseForm = () => {
    this.props.reset(FORM_NAME);
    this.setState({ showFormDialog: false });
  };

  handleShowProgress = () => {
    this.setState({ showFormDialog: false, showProgressDialog: true });
  };

  handleCloseProgress = () => {
    this.setState({
      showFormDialog: false,
      showProgressDialog: false,
      csvRows: [],
      csvFileName: "",
      csvError: null
    });
    this.props.reset(FORM_NAME);
  };

  handleSubmit = () => {
    const { submit } = this.props;
    submit(FORM_NAME);
  };

  handleSubmitForm = values => {
    const { showNotification } = this.props;

    Papa.parse(values.csv.rawFile, {
      skipEmptyLines: true,
      header: true,
      complete: ({ data, parseErrors }) => {
        let missingColumns = [];
        let mappedData = [];
        if (data[0]) {
          missingColumns = missingDeviceCsvColumns(data[0]);
          mappedData = data.map(mapDeviceParameters);
        }

        if (missingColumns.length === 0) {
          this.setState({
            showProgressDialog: true,
            showFormDialog: false,
            csvFileName: values.csv.title,
            csvRows: mappedData,
            csvError: null
          });
        } else {
          this.setState({
            showProgressDialog: true,
            showFormDialog: false,
            csvFileName: values.csv.title,
            csvRows: mappedData,
            csvError: `Please check columns in imported CSV file. These column are missing: ${missingColumns.join(
              ", "
            )}`
          });
        }
      },
      error: (err, file, inputElem, reason) => {
        showNotification(reason);
        this.setState({
          showProgressDialog: false,
          showFormDialog: true
        });
      }
    });
  };

  render() {
    const {
      showFormDialog,
      showProgressDialog,
      csvFileName,
      csvRows,
      csvError
    } = this.state;
    const { classes, isValid, actionLabel } = this.props;

    return (
      <>
        <Button
          size="small"
          variant="text"
          color="primary"
          onClick={this.handleShowForm}
          className={classes.button}
        >
          <ImportIcon className={classes.smallIcon} />
          {actionLabel}
        </Button>

        {showFormDialog && (
          <CsvImportFormDialog
            isOpen={showFormDialog}
            handleClose={this.handleCloseForm}
            isValid={isValid}
            handleSubmit={this.handleSubmit}
            reduxFormName={FORM_NAME}
            handleSubmitForm={this.handleSubmitForm}
          />
        )}

        {showProgressDialog && (
          <CsvImportProgressDialog
            isOpen={showProgressDialog}
            handleClose={this.handleCloseProgress}
            csvRows={csvRows}
            csvFileName={csvFileName}
            csvError={csvError}
            reduxFormName={FORM_NAME}
            handleSubmitForm={this.handleSubmitForm}
          />
        )}
      </>
    );
  }
}

const StyledDeviceCsvImport = withStyles(styles)(DeviceCsvImport);

const mapStateToProps = state => ({
  isSubmitting: isSubmitting(FORM_NAME)(state),
  isValid: isValid(FORM_NAME)(state)
});

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  reset
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledDeviceCsvImport);
