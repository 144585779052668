import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { refreshView as refreshViewAction, setListSelectedIds } from "ra-core";
import { showNotification } from "react-admin";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import ActionDelete from "@material-ui/icons/Delete";

import HttpClient from "../../HttpClient";
import Config from "../../Config";

const styles = theme => ({
  deleteButton: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent"
      }
    }
  }
});

class DeleteButton extends Component {
  handleClick = () => {
    const { refreshView, selectedIds, showNotification, setListSelectedIds } = this.props;
    HttpClient(`${Config.api.HOST}/rpc/bulk_delete_time_series_alerts`, {
      method: "POST",
      body: JSON.stringify({ ids: selectedIds })
    })
      .then(() => {
        showNotification("messages.data_alerts_deleted");
        setListSelectedIds("data_alerts", []);
        refreshView();
      })
      .catch(e => {
        console.error(e);
        showNotification("messages.data_alerts_not_deleted", "warning");
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <Button className={classes.deleteButton} label="Delete" onClick={this.handleClick}>
        <ActionDelete />
        Delete
      </Button>
    );
  }
}

DeleteButton.propTypes = {
  push: PropTypes.func,
  selectedIds: PropTypes.array,
  showNotification: PropTypes.func
};

export default connect(
  null,
  {
    setListSelectedIds,
    showNotification,
    refreshView: refreshViewAction
  }
)(withStyles(styles)(DeleteButton));
