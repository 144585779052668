import React, {Component} from 'react';
import {connect} from 'react-redux';
import {crudCreate } from 'react-admin';
import whitelistFormatParameters from './whitelistFormatParameters';
import addDefaultDeviceParameters from './addDefaultDeviceParameters';
import sanitizeDeviceParameters from './sanitizeDeviceParameters';
import IotaSaveButton from '../../ra-ui-iota/IotaSaveButton';

const saveSanitized = (values, basePath, redirectTo) => {
  let cleanValues = whitelistFormatParameters(values);
  cleanValues = addDefaultDeviceParameters(cleanValues);
  cleanValues = sanitizeDeviceParameters(cleanValues);

  return crudCreate('devices', cleanValues, basePath, redirectTo);
}

class CreateWithCleanedParamsView extends Component {
  handleClick = () => {
    const {basePath, handleSubmit, redirect, saveSanitized} = this.props;
    return handleSubmit(values => {
      saveSanitized(values, basePath, redirect);
    });
  };

  render() {
    const {handleSubmitWithRedirect, saveSanitized, ...props} = this.props;
    return (
      <IotaSaveButton handleSubmitWithRedirect={this.handleClick} {...props} />
    );
  }
}

const CreateWithCleanedParams = connect(
  undefined,
  {saveSanitized},
)(CreateWithCleanedParamsView);

export default CreateWithCleanedParams;
