import React from "react";
import moment from "moment";
import { DISPLAY_TIME_FORMAT } from "../ChartDataController";
import TemperatureChart from "./TemperatureChart";

export default function TemperatureChartContainer({ data, loadData, params, bucket }) {
  const [rule] = params.alert_rules || [];
  const { lower_bound, upper_bound } = rule || {};
  const chartData = data.map(d => {
    return {
      bucket: d.bucket,
      time: moment(d.time).format(DISPLAY_TIME_FORMAT),
      Tave: d.tave,
      Tmin: d.tmin !== d.tave ? d.tmin : null,
      Tmax: d.tmax !== d.tave ? d.tmax : null,
      "Lower bound": lower_bound,
      "Upper bound": upper_bound
    };
  });
  return (
    <div style={{ width: "99%" }}>
      <TemperatureChart
        height={410}
        data={chartData}
        loadData={loadData}
        canZoomIn={bucket >= params.measure_interval}
      />
    </div>
  );
}
