import React from "react";
import PropTypes from "prop-types";
import QueuedDeviceCreator from "./QueuedDeviceCreator";
import Progress from "./Progress";
import Details from "./Details";

class DialogContent extends React.Component {
  static propTypes = {
    csvFileName: PropTypes.string.isRequired,
    csvRows: PropTypes.array.isRequired
  };

  render() {
    const { csvRows } = this.props;
    return (
      <div>
        {
          <>
            {csvRows.length === 0 ? (
              <p>No devices to create.</p>
            ) : (
              <QueuedDeviceCreator csvRows={csvRows}>
                {({ status, current, completed, totalCount, startFn }) => {
                  const isRunning =
                    status === "started" || status === "checking";
                  const isFinished =
                    status === "finished" || status === "finishedErrors";
                  return (
                    <>
                      <Progress
                        status={status}
                        completedCount={completed.length}
                        totalCount={totalCount}
                      />
                      {!isRunning &&
                        isFinished && (
                          <Details
                            status={status}
                            results={completed}
                            current={current}
                            pagesCount={csvRows.length}
                          />
                        )}
                    </>
                  );
                }}
              </QueuedDeviceCreator>
            )}
          </>
        }
      </div>
    );
  }
}

export default DialogContent;
