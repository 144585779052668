import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import Charts from "../Charts";
import Meter from "./Meter";
import MeterValuesAggregator from "../Charts/MeterValuesAggregator";
import MonitoredCheckbox from "./MonitoredCheckbox";
import MonitorEdit from "../MonitorEdit";
import DeleteRecord from "../DeleteRecord";

const styles = theme => ({
  columnContainer: {
    boxSizing: "border-box",
    border: "1px solid #cdcdcd",
    borderRadius: "5px",
    marginBottom: "32px",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: "49%"
    },
    "@media (min-width: 1900px)": {
      width: "32%"
    }
  },
  columnHeader: {
    display: "flex",
    alignItems: "center",
    background: "#f8f8f8",
    borderTopLeftRadius: "inherit",
    borderTopRightRadius: "inherit",
    borderBottom: "1px solid #f3f3f3",
    padding: "0 16px"
  },
  actionButtons: {
    marginLeft: "auto",
    minWidth: "96px",
    textAlign: "right"
  },
  dataContainer: {
    padding: "0 16px"
  }
});

class MonitorColumn extends React.Component {
  elementRef = React.createRef();
  state = {
    visible: false
  };
  setVisible = () => {
    this.setState({ visible: true });
  };
  componentDidMount() {
    this.props.observeVisibility(this.elementRef.current, this.setVisible);
  }

  render() {
    const { id, data, basePath, classes } = this.props;
    const { last_values, last_time, device_id, name, message_type } = data;
    return (
      <div key={id} className={classes.columnContainer} ref={this.elementRef}>
        <div className={classes.columnHeader}>
          <MonitoredCheckbox resourceName="monitors" resourceId={device_id} />
          <Typography variant="subheading" component="h2">
            {name}
          </Typography>
          <div className={classes.actionButtons}>
            <MonitorEdit resource="monitors" basePath={basePath} record={data} />
            <DeleteRecord
              resource="monitors"
              record={data}
              title="Delete device"
              notificationKey="messages.monitor_device_removed"
            >
              <p className="m-0">Do you want to delete this device?</p>
            </DeleteRecord>
          </div>
        </div>
        {this.state.visible ? (
          <MeterValuesAggregator
            init={{ values: last_values, time: last_time }}
            message_type={message_type}
          >
            {({ aggregate, aggregateValues }) => (
              <div className={classes.dataContainer}>
                <Meter params={data} aggregate={aggregate} />
                <Charts params={data} onNewData={aggregateValues} />
              </div>
            )}
          </MeterValuesAggregator>
        ) : (
          <div style={{ height: "650px" }} />
        )}
      </div>
    );
  }
}

MonitorColumn.defaultProps = {
  data: {}
};

export default withStyles(styles)(MonitorColumn);
