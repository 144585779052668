import React from "react";
import { Snackbar } from "@material-ui/core";
import SnackbarContentWrapper from "../SnackbarContentWrapper";

export default class AuthNotification extends React.Component {
  state = {
    type: this.props.type || "error",
    message: this.props.message
  };

  setNotification = ({message, type}) => {
    this.setState({ message, type });
  }

  closeSnackbar = () => {
    this.setState({ message: null });
  };

  render() {
    const { message, type } = this.state;
    return (
      <Snackbar
        open={!!message}
        onClose={this.closeSnackbar}
        ContentProps={{ "aria-describedby": "message-id" }}
      >
        <SnackbarContentWrapper variant={type} message={message} />
      </Snackbar>
    );
  }
}
