import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Button,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import HttpClient from "../HttpClient";
import Config from "../Config";
import AuthLayout from "./AuthLayout";
import AuthNotification from "./AuthNotification";

const FAILURE_MESSAGE =
  "Password reset token was invalid, please use link from e-mail message";
const SUCCESS_MESSAGE =
  "Your password has been successfully changed, please sign in using new password.";
const PASSWORD_FAILURE_MESSAGE = "Password and confirmation did not match";
const PASSWORD_HELPER_TEXT =
  "Password must contain at least 6 characters, including uppercase/lowercase letters and numbers";
const PASSWORD_NOT_MEETING_REQUIREMENTS =
  "Entered password does not meet complexity requirements. Please try again.";
const PASSWORD_PATTERN = "(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])\\S{6,}";

class ChangePasswordPage extends Component {
  notifRef = React.createRef();

  state = {
    password: "",
    passwordConfirmation: "",
    error: false,
    success: false,
    showPassword: false,
    showPasswordConfirmation: false
  };

  handleChange = ({ target }) => {
    this.setState({
      [target.id]: target.value
    });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleClickShowPasswordConfirmation = () => {
    this.setState(state => ({
      showPasswordConfirmation: !state.showPasswordConfirmation
    }));
  };

  handleCloseSnackbar = () => {
    this.setState({ snackbarMessage: null });
  };

  submit = e => {
    e.preventDefault();
    const {
      password: new_pwd,
      passwordConfirmation: new_pwd_confirmation
    } = this.state;
    const token = this.props.match.params.token;
    if (new_pwd !== new_pwd_confirmation) {
      this.setState(
        _state => ({ email: true }),
        () =>
          this.notifRef.current.setNotification({
            type: "error",
            message: PASSWORD_FAILURE_MESSAGE
          })
      );
    } else if (!new_pwd.match(PASSWORD_PATTERN) || !new_pwd_confirmation.match(PASSWORD_PATTERN)) {
      this.setState(
        _state => ({ email: true }),
        () =>
          this.notifRef.current.setNotification({
            type: "error",
            message: PASSWORD_NOT_MEETING_REQUIREMENTS
          })
      );
    } else {
      HttpClient(Config.api.HOST + "/change_password", {
        method: "POST",
        body: JSON.stringify({ new_pwd, new_pwd_confirmation, token })
      })
        .then(result => {
          this.setState(
            _state => ({ success: true }),
            () =>
              this.notifRef.current.setNotification({
                type: "success",
                message: SUCCESS_MESSAGE
              })
          );
        })
        .catch(error => {
          this.notifRef.current.setNotification({
            type: "error",
            message: FAILURE_MESSAGE
          });
        });
    }
  };

  render() {
    const {
      success,
      error,
      password,
      passwordConfirmation,
      showPassword,
      showPasswordConfirmation
    } = this.state;

    return (
      <>
        {success && (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                notification: {
                  type: "success",
                  message: SUCCESS_MESSAGE
                }
              }
            }}
          />
        )}

        <AuthLayout snackbar={<AuthNotification ref={this.notifRef} />}>
          <Paper>
            <Typography component="h1" variant="display1">
              Change password
            </Typography>
            <form onSubmit={this.submit}>
              <TextField
                id="password"
                label="New password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={this.handleChange}
                margin="normal"
                fullWidth
                required
                error={error}
                helperText={PASSWORD_HELPER_TEXT}
                InputProps={{
                  pattern: PASSWORD_PATTERN,
                  endAdornment: (
                    <InputAdornment variant="filled" position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                id="passwordConfirmation"
                label="Repeat new password"
                type={showPasswordConfirmation ? "text" : "password"}
                value={passwordConfirmation}
                onChange={this.handleChange}
                margin="normal"
                fullWidth
                required
                error={error}
                InputProps={{
                  pattern: PASSWORD_PATTERN,
                  endAdornment: (
                    <InputAdornment variant="filled" position="end">
                      <IconButton
                        aria-label="Toggle password confirmation visibility"
                        onClick={this.handleClickShowPasswordConfirmation}
                      >
                        {showPasswordConfirmation ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Reset password
              </Button>
              <Button
                fullWidth
                variant="text"
                component={Link}
                to="/login"
                style={{ color: "black" }}
              >
                Cancel
              </Button>
            </form>
          </Paper>
        </AuthLayout>
      </>
    );
  }
}

export default ChangePasswordPage;
