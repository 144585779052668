import React from "react";
import IotaDialog from "../../../ra-ui-iota/IotaDialog";
import IotaForm from "../../../ra-ui-iota/IotaForm";
import IotaSaveButton from "../../../ra-ui-iota/IotaSaveButton";
import MonitorEditFormFields from "./MonitorEditFormFields";
import validateAlertRules from "./validateBoundingValues";

const MonitorEditFormDialog = ({
  handleCloseForm,
  handleSave,
  handleShowConfirmation,
  handleSubmitForm,
  isOpen,
  isSubmitting,
  isValid,
  record,
  reduxFormName
}) => {
  return (
    <IotaDialog
      isOpen={isOpen}
      onClose={handleCloseForm}
      dialog={{
        title: "Edit device",
        cancel: "Cancel"
      }}
      save={
        <IotaSaveButton
          color="primary"
          disabled={!isValid}
          onClick={handleSave}
          label={"Save"}
          saving={isSubmitting}
        />
      }
    >
      {isOpen && (
        <IotaForm
          validate={validateAlertRules}
          form={reduxFormName}
          resource="monitors"
          onSubmit={handleSubmitForm}
          toolbar={null}
          forceUnregisterOnUnmount={true}
          record={record}
        >
          <MonitorEditFormFields record={record} />
        </IotaForm>
      )}
    </IotaDialog>
  );
};

export default MonitorEditFormDialog;
