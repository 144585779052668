import React from "react";

import { NumberInput, required, minValue, maxValue, number } from "react-admin";

const IncrementalFormatFields = props => (
  <div className="form-row">
    <div className="w-100 w-30-m">
      <NumberInput
        source="message_format[value_bytes]"
        label="Counter length"
        className="m-0"
        fullWidth
        validate={[required(), number(), minValue(2), maxValue(24)]}
        {...props}
      />
    </div>
    <div className="w-100 w-30-m">
      <NumberInput
        source="message_format[increment_bytes]"
        label="Increment length"
        className="m-0"
        fullWidth
        validate={[required(), number(), minValue(2), maxValue(24)]}
        {...props}
      />
    </div>
    <div className="w-100 w-30-m">
      <NumberInput
        source="message_format[increment_count]"
        label="Number of increments"
        className="m-0"
        fullWidth
        validate={[required(), number(), minValue(1), maxValue(12)]}
        {...props}
      />
    </div>
  </div>
);

export default IncrementalFormatFields;
