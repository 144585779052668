import React from "react";
import BasicIncrementalMeter from "./BasicIncrementalMeter";
import TemperatureMeter from "./TemperatureMeter";
import TemphumidMeter from "./TemphumidMeter";
import Temphumidco2Meter from "./Temphumidco2Meter";

// Meter types
// 'electricity', 'gas', 'heat', 'water', 'other'
import electricity from "../../../../img/meter-electricity.svg";
import gas from "../../../../img/meter-gas.svg";
import heat from "../../../../img/meter-heat.svg";
import water from "../../../../img/meter-water.svg";
import other from "../../../../img/meter-other.svg";
import tempth from "../../../../img/meter-tempth.svg";

const METER_IMGS = {
  electricity,
  gas,
  heat,
  water,
  other,
  tempth
};

class Meter extends React.Component {
  meterFactory = message_type => {
    if (message_type === "temperature") return TemperatureMeter;
    if (message_type === "temphumid") return TemphumidMeter;
    if (message_type === "temphumidco2") return Temphumidco2Meter;
    return BasicIncrementalMeter;
  };

  meterImgFactory = ({ meter_type, message_type }) => {
    if (message_type === "temperature" || message_type === "temphumid" || message_type === "temphumidco2")  return METER_IMGS["tempth"];
    return METER_IMGS[meter_type] || METER_IMGS["other"];
  };

  render() {
    const { params, aggregate } = this.props;
    return (
      <div className="meter-container">
        <div className={`meter-relative-container ${!['temperature', 'temphumid', 'temphumidco2'].includes(params.message_type) ? 'dark' : 'light'}`}>
          <img src={this.meterImgFactory(params)} className="meter-img" alt="meter" />
          {this.meterFactory(params.message_type)({ ...params, aggregate })}
        </div>
      </div>
    );
  }
}

export default Meter;
