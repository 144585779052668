import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { fetchEnd, fetchStart, showNotification } from "react-admin";
import { refreshView as refreshViewAction } from "ra-core";

import HttpClient from "../../HttpClient";
import Config from "../../Config";

import Button from "@material-ui/core/Button";

class AddToMonitoredDevices extends Component {
  handleClick = () => {
    const { record, showNotification } = this.props;
    const { id, name, psc } = record;
    fetchStart();

    HttpClient(`${Config.api.HOST}/monitors`, {
      method: "POST",
      body: JSON.stringify({ device_id: id, name, psc })
    })
      .then(() => {
        this.props.refreshView();
        showNotification("messages.monitor_device_added");
      })
      .catch(e => {
        showNotification(e.message, "warning");
      })
      .finally(() => {
        fetchEnd();
      });
  };

  render() {
    return (
      <Button
        size="small"
        variant="flat"
        color="primary"
        onClick={this.handleClick}
      >
        add to charts
      </Button>
    );
  }
}

AddToMonitoredDevices.propTypes = {
  record: PropTypes.object,
  showNotification: PropTypes.func
};

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
  refreshView: refreshViewAction
};

export default connect(
  null,
  mapDispatchToProps
)(AddToMonitoredDevices);
