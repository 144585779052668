const parseResourceSelectedIds = (resources, resourceName) => {
  let resourceSelectedIds = [];
  const resource = resources[resourceName];

  if (resource) {
    const { list = {} } = resource;
    const { selectedIds = [] } = list;
    resourceSelectedIds = selectedIds;
  }
  return resourceSelectedIds;
};

export default parseResourceSelectedIds;
