import React from "react";
import PropTypes from "prop-types";
import { statusToName } from "./QueuedDeviceCreator";

import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const styles = {
  root: {
    flexGrow: 1
  }
};

function statusToProgressStyle(status) {
  switch (status) {
    case "finished":
      return "secondary";
    case "finishedErrors":
      return "primary";
    default:
      return "primary";
  }
}

class Progress extends React.Component {
  static propTypes = {
    completedCount: PropTypes.number,
    totalCount: PropTypes.number,
    status: PropTypes.string
  };

  render() {
    const { completedCount, totalCount, status, classes } = this.props;
    const progress =
      totalCount === 0 ? 0 : Math.floor(100 * (completedCount / totalCount));
    return (
      <div>
        <h4>Progress: {statusToName(status)}</h4>
        <div className={classes.root}>
          <LinearProgress
            color={statusToProgressStyle(status)}
            variant="determinate"
            value={progress}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Progress);
