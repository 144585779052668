import React, { Component } from "react";
import { Redirect } from "react-router";
import { Button, Paper, TextField, Typography } from "@material-ui/core";

import { hideNotification } from "react-admin";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import AuthNotification from "./AuthNotification";

import sessionFromToken from "./sessionFromToken";
// import HttpClient from "../HttpClient";
import Config from "../Config";
import { themeExtension } from "../themeExtension";

import { withStyles } from "@material-ui/core/styles";

const FAILURE_MESSAGE = "Invalid or expired token supplied";
const TOKEN_FAILURE_MESSAGE = "Access expired, please sign in again";

const styles = theme => ({
  pushedTop: {
    marginTop: theme.spacing.unit * 5
  },
  viewerButton: themeExtension.tertiaryButton
});

class LoginViewerPage extends Component {
  notifRef = React.createRef();

  state = {
    token: "",
    formSubmitted: false,
    submissionSuccess: false
  };

  handleChange = ({ target }) => {
    this.setState({ [target.id]: target.value });
  };

  submit = e => {
    e.preventDefault();
    const { token } = this.state;
    this.setState({ formSubmitted: true });

    fetch(`${Config.api.HOST}/monitors`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`
      }
    })
      .then(response =>
        response.text().then(text => ({
          status: response.status
        }))
      )
      .then(({ status }) => {
        if (status < 200 || status >= 300) {
          return this.notifRef.current.setNotification({
            type: "error",
            message: FAILURE_MESSAGE
          });
        } else {
          sessionFromToken(token);
          this.setState({ submissionSuccess: true });
        }
      });
  };

  // redirect 401 nie powoduje zwiększenia liczby notifications
  // dlatego tutaj pokazanie błędu formularza zadziała
  // natomiast w przypadku przekierowań zadziała componentDidMount
  componentDidUpdate(prevProps) {
    if (prevProps.notifications.length !== this.props.notifications.length) {
      if (this.props.notifications.length > 0) {
        this.props.hideNotification();
        this.notifRef.current.setNotification({
          type: "error",
          message: FAILURE_MESSAGE
        });
      }
    }
  }

  componentDidMount() {
    const { state } = this.props.location;

    if (state) {
      const { notification, nextPathname } = state;
      if (notification && nextPathname) {
        this.notifRef.current.setNotification({
          type: "error",
          message: TOKEN_FAILURE_MESSAGE
        });
      } else if (notification) {
        const { type, message } = notification;
        this.notifRef.current.setNotification({ type, message });
      }
    }

    localStorage.setItem("lastLoginPage", "/login_viewer");
  }

  render() {
    const { token, submissionSuccess } = this.state;
    const { classes } = this.props;

    return (
      <AuthLayout snackbar={<AuthNotification ref={this.notifRef} />}>
        { submissionSuccess && <Redirect to="/monitors" /> }
        <Paper>
          <Typography component="h1" variant="display1">
            Sign in as a Viewer
          </Typography>

          <p>Type your token to sign in to your Viewer account.</p>

          <form onSubmit={this.submit}>
            <TextField
              id="token"
              label="Token"
              type="text"
              value={token}
              onChange={this.handleChange}
              margin="normal"
              fullWidth
              required
              autoFocus={true}
              multiline={true}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.viewerButton}
            >
              Login as a viewer
            </Button>

            <p className={classes.pushedTop}>No account yet?</p>

            <Button
              fullWidth
              component={Link}
              to="/register_viewer"
              variant="outlined"
              color="secondary"
            >
              Register viewer
            </Button>

            <Button
              fullWidth
              component={Link}
              to="/login"
              variant="flat"
              color="primary"
              className={classes.pushedTop}
            >
              Back to Owner App
            </Button>
          </form>
        </Paper>
      </AuthLayout>
    );
  }
}

const StyledLoginViewerPage = withStyles(styles)(LoginViewerPage);

const mapStateToProps = ({ admin }) => {
  return { notifications: admin.notifications };
};

export default connect(
  mapStateToProps,
  { push, hideNotification }
)(StyledLoginViewerPage);
