const dev = {
  api: {
    HOST: "https://www.reader.iota-devices.com/api"
  }
};

const stg = {
  api: {
    HOST: "https://www.reader.iota-devices.com/api"
  }
};

const prod = {
  api: {
    HOST: "https://www.reader.iota-devices.com/api"
  }
};

let Config;
switch (process.env.REACT_APP_STAGE) {
  case "production":
    Config = prod;
    break;
  case "staging":
    Config = stg;
    break;
  default:
    Config = dev;
    break;
}

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...Config
};
