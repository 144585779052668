import React from 'react';
import createSvgIcon from '../utils/createSvgIcon';
export default createSvgIcon(
  React.createElement(
    'g',
    null,
    React.createElement('path', {
      d: 'M5 9.2h3V19H5zM10.6 5h2.8v14h-2.8zm5.6 8H19v6h-2.8z',
    }),
    React.createElement('path', {
      d: 'M0 0h24v24H0z',
      fill: 'none',
    }),
  ),
  'BarChart',
);
