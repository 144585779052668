import React from "react";
import IotaDialog from "../../../../ra-ui-iota/IotaDialog";
import IotaForm from "../../../../ra-ui-iota/IotaForm";
import IotaSaveButton from "../../../../ra-ui-iota/IotaSaveButton";
import XlsFormFields from "./XlsFormFields";

const XlsFormDialog = ({
  handleClose,
  handleSubmit,
  handleSubmitForm,
  isOpen,
  isSubmitting,
  isValid,
  reduxFormName,
  selectedIds
}) => (
  <IotaDialog
    isOpen={isOpen}
    onClose={handleClose}
    dialog={{
      title: "Download XLSX file",
      cancel: "Cancel"
    }}
    save={
      <IotaSaveButton
        color="primary"
        disabled={!isValid || isSubmitting}
        onClick={handleSubmit}
        label={"Download"}
        saving={isSubmitting}
      />
    }
  >
    <p className="m-0">
      You are exporting reports for selected monitor devices:{" "}
      <strong style={{color: "black"}}>{selectedIds.join(", ")}</strong>
    </p>
    {isOpen && (
      <IotaForm
        form={reduxFormName}
        resource="devices"
        onSubmit={handleSubmitForm}
        toolbar={null}
        forceUnregisterOnUnmount={true}
      >
        <XlsFormFields />
      </IotaForm>
    )}
  </IotaDialog>
);

export default XlsFormDialog;
