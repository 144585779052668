import { AUTH_LOGIN, AUTH_CHECK, AUTH_LOGOUT, AUTH_ERROR, AUTH_GET_PERMISSIONS } from "react-admin";
import Config from "./Config";
import sessionFromToken from "./auth/sessionFromToken";

const RESTRICTED = {
  devices: ["owner", "appadmin"],
  time_series_data: ["owner", "appadmin"],
  transmit_intervals: ["owner", "appadmin"],
  delete_devices: ["appadmin"]
};

export const isAllowed = role => resource => RESTRICTED[resource].includes(role);

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(Config.api.HOST + "/rpc/login", {
      method: "POST",
      body: JSON.stringify({ email: username, pwd: password }),
      headers: new Headers({
        Accept: "application/vnd.pgrst.object+json",
        "Content-Type": "application/json"
      })
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(json => {
        sessionFromToken(json["token"]);
      });
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    const status = params.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      return Promise.reject();
    }
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem("token")
      ? Promise.resolve()
      : Promise.reject({ redirectTo: localStorage.getItem("lastLoginPage") });
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem("role");
    return role ? Promise.resolve(role) : Promise.resolve("anon");
  }
  return Promise.reject("Unknown method");
};
