export default function prepareAlertRules(values) {
  const { name, alert_rule_0, alert_rule_1, alert_rule_2, message_type } = values;
  let alert_rules = null;

  const rule_0 = prepareRuleValue(alert_rule_0);
  if (rule_0) alert_rules = [rule_0];
  if (message_type === "temphumid" && alert_rule_1) {
    const rule_1 = prepareRuleValue(alert_rule_1);
    if (rule_1) alert_rules ? alert_rules.push(rule_1) : (alert_rules = [null, rule_1]);
  }

  if (message_type === "temphumidco2" && (alert_rule_1 || alert_rule_2)) {
    if (alert_rule_1) {
      const rule_1 = prepareRuleValue(alert_rule_1);
      if (rule_1) alert_rules ? alert_rules.push(rule_1) : (alert_rules = [null, rule_1, null]);
    }

    if (alert_rule_2) {
      const rule_2 = prepareRuleValue(alert_rule_2);
      if (rule_2) alert_rules ? alert_rules.push(rule_2) : (alert_rules = [null, null, rule_2]);
    }
  }
  console.log({ name, alert_rules })
  return { name, alert_rules };
}

const prepareRuleValue = rule => {
  if (!rule || (!rule.lower_bound && !rule.upper_bound)) return null;
  rule.bounding = "[]";
  rule.inclusion = "e";
  return rule;
};
